// Generated by ReScript, PLEASE EDIT WITH CARE

import WildcardsLoaderJs from "./WildcardsLoader.js";

var make = WildcardsLoaderJs;

export {
  make ,
}
/* make Not a pure module */
