// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../../Styles.bs.js";
import * as QlHooks from "../QlHooks.bs.js";
import * as MomentRe from "bs-moment/src/MomentRe.bs.js";
import * as CountDown from "../CountDown.bs.js";
import * as Web3Utils from "../Web3Utils.bs.js";
import * as RimbleUi from "rimble-ui";
import * as Accounting from "../Accounting.bs.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FormatMoney from "../../components/components/FormatMoney.bs.js";
import * as PriceDisplay from "../PriceDisplay.bs.js";
import * as RootProvider from "../RootProvider.bs.js";
import * as UserProvider from "../js/user-provider/UserProvider.bs.js";
import ReactSwitch from "react-switch";
import * as ReactTranslate from "../../helpers/providers/ReactTranslate.bs.js";
import * as UsdPriceProvider from "./UsdPriceProvider.bs.js";

function Info$ExpertView(props) {
  var daysHeld = props.daysHeld;
  var definiteTime = props.definiteTime;
  var optMonthlyPledgeUsd = props.optMonthlyPledgeUsd;
  var showEthWithUsdConversion = props.showEthWithUsdConversion;
  var unit = props.unit;
  var optMonthlyPledgeEth = props.optMonthlyPledgeEth;
  var tokenName = props.tokenName;
  var clearAndPush = RootProvider.useClearNonUrlStateAndPushRoute(undefined);
  var tmp;
  if (definiteTime !== undefined) {
    var date = Caml_option.valFromOption(definiteTime);
    tmp = React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Foreclosure date: ", React.createElement(RimbleUi.Tooltip, {
                      message: "This is the date the deposit will run out and the current owner will lose guardianship of " + tokenName,
                      placement: "top",
                      children: React.createElement("span", undefined, "ⓘ")
                    }))), React.createElement("br", undefined), MomentRe.Moment.format("LLLL", date), React.createElement("br", undefined), React.createElement("small", undefined, "( ", React.createElement(CountDown.make, {
                  endDateMoment: date
                }), ")"));
  } else {
    tmp = null;
  }
  var tmp$1;
  if (daysHeld !== undefined) {
    var timeAcquiredString = MomentRe.Moment.toISOString(undefined, daysHeld[1]);
    tmp$1 = React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Days Held: ", React.createElement(RimbleUi.Tooltip, {
                      message: "This is the amount of time " + (tokenName + (" has been held. It was acquired on the " + (timeAcquiredString + "."))),
                      placement: "top",
                      children: React.createElement("span", undefined, "ⓘ")
                    }))), React.createElement("br", undefined), daysHeld[0].toFixed(), React.createElement("br", undefined));
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement("div", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Monthly Pledge (at " + (props.monthlyRate + "%): "), React.createElement(RimbleUi.Tooltip, {
                              message: "This is the monthly percentage contribution of " + (tokenName + "'s sale price that will go towards conservation of at risk animals. This is deducted continuously from the deposit and paid by the owner of the animal"),
                              placement: "top",
                              children: React.createElement("span", undefined, "ⓘ")
                            }))), React.createElement("br", undefined), optMonthlyPledgeEth !== undefined ? optMonthlyPledgeEth + (" " + unit) : React.createElement(RimbleUi.Loader, {}), React.createElement("br", undefined), showEthWithUsdConversion && optMonthlyPledgeUsd !== undefined ? React.createElement("small", undefined, "(" + (optMonthlyPledgeUsd + " USD)")) : null), React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Current Patron: ", React.createElement(RimbleUi.Tooltip, {
                              message: "This is the $userIdType of the current owner",
                              placement: "top",
                              children: React.createElement("span", undefined, "ⓘ")
                            }))), React.createElement("br", undefined), React.createElement("a", {
                      onClick: (function (e) {
                          e.preventDefault();
                          Curry._1(clearAndPush, "/#user/$currentPatron");
                        })
                    }, props.displayNameStr)), React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Available Deposit: ", React.createElement(RimbleUi.Tooltip, {
                              message: "This is the amount the owner has deposited to pay their monthly contribution",
                              placement: "top",
                              children: React.createElement("span", undefined, "ⓘ")
                            }))), React.createElement("br", undefined), props.depositAvailableToWithdrawEth + (" " + unit), React.createElement("br", undefined), showEthWithUsdConversion ? React.createElement("small", undefined, "(" + (props.depositAvailableToWithdrawUsd + " USD)")) : null), React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, tokenName + "'s Patronage: ", React.createElement(RimbleUi.Tooltip, {
                              message: "This is the total contribution that has been raised thanks to the wildcard, " + tokenName,
                              placement: "top",
                              children: React.createElement("span", undefined, "ⓘ")
                            }))), React.createElement("br", undefined), props.totalPatronage + (" " + unit), React.createElement("br", undefined), showEthWithUsdConversion ? React.createElement("small", undefined, "(" + (props.totalPatronageUsd + " USD)")) : null), tmp, tmp$1);
}

var ExpertView = {
  make: Info$ExpertView
};

function Info$SimpleView(props) {
  var daysHeld = props.daysHeld;
  var definiteTime = props.definiteTime;
  var unit = props.unit;
  var optMonthlyPledgeEth = props.optMonthlyPledgeEth;
  var tokenName = props.tokenName;
  var clearAndPush = RootProvider.useClearNonUrlStateAndPushRoute(undefined);
  var linkStyle = Curry._1(Css.style, {
        hd: Css.textDecoration(Css.underline),
        tl: /* [] */0
      });
  var userLink = React.createElement("a", {
        className: linkStyle,
        onClick: (function (e) {
            e.preventDefault();
            Curry._1(clearAndPush, "/#user/$currentPatron");
          })
      }, props.displayNameStr);
  var orgLink = React.createElement("a", {
        className: linkStyle,
        onClick: (function (e) {
            e.preventDefault();
            Curry._1(clearAndPush, "/#org/$orgId");
          })
      }, props.orgName);
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement("p", undefined, tokenName + " is currently protected by ", userLink, " who values their guardianship of " + (tokenName + (" at " + (props.priceString + (". " + (tokenName + (" has a monthly pledge rate of " + (props.monthlyRate + "%. This means "))))))), userLink, " has to contribute " + ((
                    optMonthlyPledgeEth !== undefined ? optMonthlyPledgeEth + (" " + unit) : "Loading"
                  ) + " monthly to "), orgLink, " for the protection of " + (tokenName + (Belt_Option.mapWithDefault(props.optionalSpecies, "", (function (species) {
                            return " the " + species;
                          })) + "."))), daysHeld !== undefined ? React.createElement("p", undefined, userLink, " has been the guardian of " + (tokenName + (" for " + (daysHeld[0].toFixed() + " days "))), definiteTime !== undefined ? React.createElement(React.Fragment, undefined, "and has enough deposit to last ", React.createElement(CountDown.make, {
                              endDateMoment: Caml_option.valFromOption(definiteTime)
                            }), " - remember to keep topping up that deposit 😉.") : null) : null, React.createElement("p", undefined, "" + (tokenName + (" has earned " + (props.totalPatronage + (" " + (unit + " for "))))), orgLink, ". Congratulations to all the honourable and loyal patrons of " + (tokenName + "!")));
}

var SimpleView = {
  make: Info$SimpleView
};

function Info(props) {
  var tokenId = props.tokenId;
  var chain = props.chain;
  var daysHeld = QlHooks.useDaysHeld(chain, tokenId);
  var currentPatron = Belt_Option.getWithDefault(QlHooks.usePatron(chain, tokenId), "Loading");
  var userId = UserProvider.useDisplayName(currentPatron);
  var displayName = UserProvider.useDisplayName(currentPatron);
  var displayNameStr = UserProvider.displayNameToString(displayName);
  var tokenName = Belt_Option.getWithDefault(QlHooks.useWildcardName(tokenId), "loading name");
  var userIdType;
  switch (userId.TAG | 0) {
    case /* TwitterHandle */0 :
        userIdType = "verified twitter account";
        break;
    case /* ThreeBoxName */1 :
        userIdType = "3box name";
        break;
    case /* EthAddress */2 :
        userIdType = "public address";
        break;
    
  }
  var currentUsdEthPrice = UsdPriceProvider.useUsdPrice(undefined);
  var match = Belt_Option.mapWithDefault(QlHooks.useRemainingDepositEth(chain, currentPatron), [
        "Loading",
        "Loading"
      ], (function (a) {
          return [
                  FormatMoney.toFixedWithPrecisionNoTrailingZeros(Belt_Option.getWithDefault(Belt_Float.fromString(FormatMoney.getEthUnit(a, {
                                    TAG: /* Eth */0,
                                    _0: "ether"
                                  })), 0.0), 9),
                  Belt_Option.mapWithDefault(currentUsdEthPrice, "Loading", (function (usdEthRate) {
                          return FormatMoney.getEthUnit(a, {
                                      TAG: /* Usd */1,
                                      _0: usdEthRate,
                                      _1: 2
                                    });
                        }))
                ];
        }));
  var match$1 = Belt_Option.mapWithDefault(QlHooks.useAmountRaisedToken(chain, tokenId), [
        "Loading",
        "Loading"
      ], (function (a) {
          return [
                  FormatMoney.toFixedWithPrecisionNoTrailingZeros(Belt_Option.getWithDefault(Belt_Float.fromString(FormatMoney.getEthUnit(a, {
                                    TAG: /* Eth */0,
                                    _0: "ether"
                                  })), 0.0), 9),
                  Belt_Option.mapWithDefault(currentUsdEthPrice, "Loading", (function (usdEthRate) {
                          return FormatMoney.getEthUnit(a, {
                                      TAG: /* Usd */1,
                                      _0: usdEthRate,
                                      _1: 2
                                    });
                        }))
                ];
        }));
  var totalPatronage = match$1[0];
  var foreclosureTime = QlHooks.useForeclosureTime(chain, currentPatron);
  var definiteTime = Belt_Option.mapWithDefault(foreclosureTime, undefined, (function (a) {
          return Caml_option.some(a);
        }));
  var ratio = QlHooks.usePledgeRate(chain, tokenId);
  var optCurrentPrice = PriceDisplay.usePrice(chain, tokenId);
  var match$2 = optCurrentPrice !== undefined ? [
      FormatMoney.toFixedWithPrecisionNoTrailingZeros(Accounting.defaultZeroF(Belt_Float.fromString(optCurrentPrice[0])) * ratio, 4),
      undefined
    ] : [
      undefined,
      undefined
    ];
  var optMonthlyPledgeEth = match$2[0];
  var monthlyRate = (ratio * 100).toString();
  var showEthWithUsdConversion = chain !== 1;
  var unit = showEthWithUsdConversion ? "ETH" : "USD";
  var translationModeContext = ReactTranslate.useTranslationModeContext(undefined);
  var orgName = Belt_Option.getWithDefault(QlHooks.useWildcardOrgName(tokenId), " the organisation");
  var orgId = Belt_Option.getWithDefault(QlHooks.useWildcardOrgId(tokenId), " the organisation");
  var currentPriceWei = QlHooks.usePrice(chain, tokenId);
  var priceString = typeof currentPriceWei === "number" ? "Loading" : Web3Utils.fromWeiBNToEthPrecision(currentPriceWei._0, 2) + (" " + unit);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Css.grey),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent("right"),
                              tl: {
                                hd: Css.alignItems("right"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("small", {
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          Curry._1(translationModeContext.setTranslationModeCrypto, !translationModeContext.translationModeCrypto);
                        })
                    }, translationModeContext.translationModeCrypto ? "EXPERT MODE " : "DEFAULT MODE "), React.createElement(ReactSwitch, {
                      onChange: translationModeContext.setTranslationModeCrypto,
                      checked: translationModeContext.translationModeCrypto,
                      onColor: "#6BAD3F",
                      onHandleColor: "#346D4C",
                      offHandleColor: "#aaaaaa",
                      uncheckedIcon: false,
                      checkedIcon: false,
                      height: 16,
                      width: 30,
                      handleDiameter: 18,
                      className: Styles.translationSwitch
                    })), translationModeContext.translationModeCrypto ? React.createElement(Info$ExpertView, {
                    monthlyRate: monthlyRate,
                    tokenName: tokenName,
                    optMonthlyPledgeEth: optMonthlyPledgeEth,
                    unit: unit,
                    showEthWithUsdConversion: showEthWithUsdConversion,
                    optMonthlyPledgeUsd: match$2[1],
                    userIdType: userIdType,
                    currentPatron: currentPatron,
                    displayNameStr: displayNameStr,
                    depositAvailableToWithdrawEth: match[0],
                    depositAvailableToWithdrawUsd: match[1],
                    totalPatronage: totalPatronage,
                    totalPatronageUsd: match$1[1],
                    definiteTime: definiteTime,
                    daysHeld: daysHeld
                  }) : React.createElement(Info$SimpleView, {
                    monthlyRate: monthlyRate,
                    tokenName: tokenName,
                    optMonthlyPledgeEth: optMonthlyPledgeEth,
                    unit: unit,
                    currentPatron: currentPatron,
                    displayNameStr: displayNameStr,
                    totalPatronage: totalPatronage,
                    definiteTime: definiteTime,
                    daysHeld: daysHeld,
                    orgId: orgId,
                    orgName: orgName,
                    priceString: priceString,
                    optionalSpecies: undefined
                  }));
}

function Info$Auction(props) {
  var abandoned = props.abandoned;
  var tokenId = props.tokenId;
  var chain = props.chain;
  var currentPatron = Belt_Option.getWithDefault(QlHooks.usePatron(chain, tokenId), "Loading");
  var displayName = UserProvider.useDisplayName(currentPatron);
  var displayNameStr = UserProvider.displayNameToString(displayName);
  var tokenName = Belt_Option.getWithDefault(QlHooks.useWildcardName(tokenId), "loading name");
  var clearAndPush = RootProvider.useClearNonUrlStateAndPushRoute(undefined);
  var currentUsdEthPrice = UsdPriceProvider.useUsdPrice(undefined);
  var match = Belt_Option.mapWithDefault(QlHooks.useAmountRaisedToken(chain, tokenId), [
        "Loading",
        "Loading"
      ], (function (a) {
          return [
                  FormatMoney.toFixedWithPrecisionNoTrailingZeros(Belt_Option.getWithDefault(Belt_Float.fromString(FormatMoney.getEthUnit(a, {
                                    TAG: /* Eth */0,
                                    _0: "ether"
                                  })), 0.0), 9),
                  Belt_Option.mapWithDefault(currentUsdEthPrice, "Loading", (function (usdEthRate) {
                          return FormatMoney.getEthUnit(a, {
                                      TAG: /* Usd */1,
                                      _0: usdEthRate,
                                      _1: 2
                                    });
                        }))
                ];
        }));
  var ratio = QlHooks.usePledgeRate(chain, tokenId);
  var monthlyRate = (ratio * 100).toString();
  var showEthWithUsdConversion = chain !== 1;
  var unit = showEthWithUsdConversion ? "ETH" : "USD";
  return React.createElement(React.Fragment, {
              children: null
            }, React.createElement("div", undefined, ratio === 0 ? React.createElement("p", undefined, "The monthly pledge rate will be revealed at launch.") : React.createElement(React.Fragment, undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Monthly Pledge Rate:", React.createElement(RimbleUi.Tooltip, {
                                    message: "This is the monthly percentage contribution of " + (tokenName + "'s sale price that will go towards conservation of at risk animals. This is deducted continuously from the deposit and paid by the guardian of the animal"),
                                    placement: "top",
                                    children: React.createElement("span", undefined, "ⓘ")
                                  }))), React.createElement("br", undefined), monthlyRate + " %")), abandoned ? React.createElement("p", undefined, React.createElement("strong", undefined, "The previous guardian was ", React.createElement("a", {
                            onClick: (function (e) {
                                e.preventDefault();
                                Curry._1(clearAndPush, "/#user/$currentPatron");
                              })
                          }, displayNameStr), React.createElement(RimbleUi.Tooltip, {
                            message: "This happens when the user's deposit runs out for the wildcard.",
                            placement: "top",
                            children: React.createElement("span", undefined, "ⓘ")
                          })), React.createElement("br", undefined)) : React.createElement("p", undefined, tokenName + " has never had a guardian - you can be the first."), React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, tokenName + "'s Patronage: ", React.createElement(RimbleUi.Tooltip, {
                              message: "This is the total contribution that has been raised thanks to " + tokenName,
                              placement: "top",
                              children: React.createElement("span", undefined, "ⓘ")
                            }))), React.createElement("br", undefined), match[0] + (" " + unit), React.createElement("br", undefined), showEthWithUsdConversion ? React.createElement("small", undefined, "(" + (match[1] + " USD)")) : null), abandoned ? React.createElement(React.Fragment, undefined, React.createElement("p", undefined, React.createElement("small", undefined, React.createElement("strong", undefined, "Abandoned since: ", React.createElement(RimbleUi.Tooltip, {
                                    message: "This is the date the deposit ran out and the current guardian will lose guardianship of " + tokenName,
                                    placement: "top",
                                    children: React.createElement("span", undefined, "ⓘ")
                                  }))), React.createElement("br", undefined), MomentRe.Moment.format("LLLL", props.auctionStartTime), React.createElement("br", undefined))) : null);
}

var Auction = {
  make: Info$Auction
};

var make = Info;

export {
  ExpertView ,
  SimpleView ,
  make ,
  Auction ,
}
/* Css Not a pure module */
