// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as RootProvider from "../harberger-lib/RootProvider.bs.js";

function Validate(props) {
  var goToVerifyUser = RootProvider.useVerifyUser(undefined);
  return React.createElement(React.Fragment, {
              children: React.createElement(RimbleUi.Box, {
                    p: 1,
                    children: React.createElement(RimbleUi.Button, {
                          children: "Profile",
                          onClick: (function (_e) {
                              Curry._1(goToVerifyUser, undefined);
                            })
                        })
                  })
            });
}

var make = Validate;

export {
  make ,
}
/* react Not a pure module */
