// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as CnRe from "rescript-classnames/src/CnRe.bs.js";
import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.bs.js";
import * as React from "react";
import * as Animal from "../harberger-lib/Animal.bs.js";
import * as Styles from "../Styles.bs.js";
import * as Amounts from "./Amounts.bs.js";
import * as QlHooks from "../harberger-lib/QlHooks.bs.js";
import * as TokenId from "../harberger-lib/TokenId.bs.js";
import * as CONSTANTS from "../CONSTANTS.bs.js";
import * as RimbleUi from "rimble-ui";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "./UserProfile.bs.js";
import * as RootProvider from "../harberger-lib/RootProvider.bs.js";
import * as JsxPPXReactSupport from "rescript/lib/es6/jsxPPXReactSupport.js";
import ReactPhotoGallery from "react-photo-gallery";
import ReactResponsiveCarousel from "react-responsive-carousel";
import YoutubeVideoJs from "./StaticContent//YoutubeVideo.js";

function orgDescriptionArray_decode(v) {
  return Spice.arrayFromJson(Spice.stringFromJson, v);
}

var make = YoutubeVideoJs;

var YoutubeVid = {
  make: make
};

function OrgProfile$ComingSoonAnimal(props) {
  var onClick = props.onClick;
  return React.createElement("div", {
              className: CssJs.style([CssJs.width({
                          NAME: "percent",
                          VAL: 32
                        })])
            }, React.createElement("img", {
                  className: CssJs.style([
                        CssJs.width({
                              NAME: "percent",
                              VAL: 100
                            }),
                        Styles.imageHoverStyle
                      ]),
                  src: props.image,
                  onClick: (function (_e) {
                      Curry._1(onClick, undefined);
                    })
                }));
}

var ComingSoonAnimal = {
  make: OrgProfile$ComingSoonAnimal
};

function OrgProfile$ImageCarousel(props) {
  return React.createElement(ReactResponsiveCarousel, {
              children: Belt_Array.mapWithIndex(props.orgComingSoon, (function (key, animal) {
                      return Belt_Option.mapWithDefault(Belt_Array.get(animal.real_wc_photos, 0), null, (function (photo) {
                                    return React.createElement(React.Fragment, undefined, React.createElement("img", {
                                                    key: String(key),
                                                    src: CONSTANTS.cdnBase + photo.image
                                                  }), Belt_Option.mapWithDefault(photo.photographer, null, (function (photographer) {
                                                      return React.createElement("p", undefined, "📸 by " + photographer + "");
                                                    })));
                                  }));
                    })),
              showArrows: true,
              showStatus: true,
              showIndicators: true,
              infiniteLoop: true,
              showThumbs: false,
              useKeyboardArrows: true,
              autoPlay: true,
              stopOnHover: true,
              swipeable: true,
              dynamicHeight: true,
              emulateTouch: true,
              selectedItem: props.selectedIndex
            });
}

var ImageCarousel = {
  make: OrgProfile$ImageCarousel
};

function OrgProfile$ComingSoonModal(props) {
  var orgComingSoon = props.orgComingSoon;
  var setSelectedComingSoonAnimal = props.setSelectedComingSoonAnimal;
  var selectedComingSoonAnimal = props.selectedComingSoonAnimal;
  var match = React.useState(function () {
        
      });
  var setOpenImage = match[1];
  var openImage = match[0];
  var tmp;
  if (selectedComingSoonAnimal !== undefined) {
    if (openImage !== undefined) {
      tmp = React.createElement(OrgProfile$ImageCarousel, {
            orgComingSoon: orgComingSoon,
            selectedIndex: openImage
          });
    } else {
      var animal = orgComingSoon[selectedComingSoonAnimal];
      tmp = React.createElement("div", undefined, React.createElement("h3", {
                className: Curry._1(Css.style, {
                      hd: Css.textAlign(Css.center),
                      tl: /* [] */0
                    })
              }, Belt_Option.getWithDefault(animal.name, "Unnamed") + Belt_Option.mapWithDefault(animal.commonName, "", (function (commonName) {
                      return " - " + commonName;
                    }))), React.createElement(RimbleUi.Flex, {
                children: null,
                flexWrap: "wrap",
                alignItems: "start",
                alignContent: "space-around"
              }, React.createElement(RimbleUi.Box, {
                    children: React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxHeight({
                                      NAME: "em",
                                      VAL: 26
                                    }),
                                tl: {
                                  hd: Css.overflow("scroll"),
                                  tl: /* [] */0
                                }
                              })
                        }, Belt_Array.mapWithIndex(Belt_Result.getWithDefault(QlHooks.animalDescription_decode(animal.description), []), (function (i, paragraphText) {
                                return React.createElement("p", {
                                            key: String(i)
                                          }, paragraphText);
                              }))),
                    width: [
                      1,
                      1,
                      0.49
                    ],
                    className: Curry._1(Css.style, {
                          hd: Css.textAlign("center"),
                          tl: {
                            hd: Css.alignSelf(Css.center),
                            tl: {
                              hd: Css.padding(Css.em(0.5)),
                              tl: /* [] */0
                            }
                          }
                        })
                  }), React.createElement(RimbleUi.Box, {
                    children: React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.maxHeight({
                                      NAME: "vh",
                                      VAL: 80
                                    }),
                                tl: /* [] */0
                              })
                        }, React.createElement(ReactPhotoGallery, {
                              photos: Belt_Array.map(animal.real_wc_photos, (function (photo) {
                                      return {
                                              src: CONSTANTS.cdnBase + photo.image,
                                              width: 4,
                                              height: 3
                                            };
                                    })),
                              targetRowHeight: 30,
                              onClick: (function (param, photoData) {
                                  Curry._1(setOpenImage, (function (param) {
                                          return photoData.index;
                                        }));
                                })
                            })),
                    width: [
                      1,
                      1,
                      0.45
                    ],
                    className: Curry._1(Css.style, {
                          hd: Css.textAlign("center"),
                          tl: {
                            hd: Css.alignSelf(Css.center),
                            tl: {
                              hd: Css.padding(Css.em(2)),
                              tl: /* [] */0
                            }
                          }
                        })
                  })));
    }
  } else {
    tmp = null;
  }
  return React.createElement(RimbleUi.Modal, {
              isOpen: Belt_Option.isSome(selectedComingSoonAnimal),
              children: React.createElement(RimbleUi.Card, {
                    width: {
                      TAG: /* AnyStr */0,
                      _0: "80vw"
                    },
                    p: 0,
                    children: null
                  }, React.createElement(RimbleUi.Button.Text, {
                        onClick: (function (param) {
                            if (openImage !== undefined) {
                              return Curry._1(setOpenImage, (function (param) {
                                            
                                          }));
                            } else {
                              return Curry._1(setSelectedComingSoonAnimal, undefined);
                            }
                          }),
                        icononly: true,
                        icon: "Close",
                        color: "moon-gray",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        m: 1
                      }), React.createElement(RimbleUi.Box, {
                        p: 1,
                        mb: 1,
                        children: tmp
                      }))
            });
}

var ComingSoonModal = {
  make: OrgProfile$ComingSoonModal
};

function OrgProfile$OrgPage(props) {
  var orgId = props.orgId;
  var orgData = props.orgData;
  var orgName = orgData.name;
  var orgDescription = Spice.arrayFromJson(Spice.stringFromJson, orgData.description);
  var orgAnimals = orgData.wildcard;
  var orgComingSoon = orgData.unlaunched;
  var selectedComingSoonAnimal = Belt_Option.flatMap(props.selectedWildcardKey, (function (wildcardKey) {
          return Belt_Array.getIndexBy(orgComingSoon, (function (orgAnimal) {
                        return wildcardKey === orgAnimal.key;
                      }));
        }));
  var clearAndPush = RootProvider.useClearNonUrlStateAndPushRoute(undefined);
  var setSelectedComingSoonAnimal = function (optKey) {
    Curry._1(clearAndPush, Belt_Option.mapWithDefault(optKey, "#org/" + orgId + "", (function (key) {
                return "#org/" + orgId + "/" + String(key) + "";
              })));
  };
  var orgAnimalsArray = Belt_Array.map(orgAnimals, (function (animal) {
          return Belt_Option.getWithDefault(animal.id, TokenId.makeFromInt(99999));
        }));
  var match = QlHooks.useTotalRaisedAnimalGroup(orgAnimalsArray);
  var totalCollectMaticDai = match[1];
  var totalCollectedMainnetEth = match[0];
  var orgWebsite = orgData.website;
  var optOrgYoutubeVid = orgData.youtube_vid;
  var orgImage = Animal.useGetOrgImage(orgId);
  var tmp;
  tmp = orgDescription.TAG === /* Ok */0 ? Belt_Array.mapWithIndex(orgDescription._0, (function (i, paragraphText) {
            return React.createElement("p", {
                        key: String(i),
                        className: CssJs.style([CssJs.textAlign("justify")])
                      }, paragraphText);
          })) : React.createElement("p", undefined, "error loading description");
  return React.createElement("div", undefined, React.createElement(OrgProfile$ComingSoonModal, {
                  selectedComingSoonAnimal: selectedComingSoonAnimal,
                  setSelectedComingSoonAnimal: setSelectedComingSoonAnimal,
                  orgComingSoon: orgComingSoon
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width({
                              NAME: "percent",
                              VAL: 100
                            }),
                        tl: /* [] */0
                      })
                }, React.createElement(RimbleUi.Flex, {
                      children: null,
                      flexWrap: "wrap",
                      alignItems: "start",
                      alignContent: "space-around"
                    }, React.createElement(RimbleUi.Box, {
                          children: null,
                          width: [
                            1,
                            1,
                            0.3333
                          ],
                          className: Curry._1(Css.style, {
                                hd: Css.textAlign("center"),
                                tl: {
                                  hd: Css.alignSelf(Css.center),
                                  tl: {
                                    hd: Css.padding(Css.em(2)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("a", {
                              className: CnRe.fromList({
                                    hd: Styles.navListText,
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Css.em(3)),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              href: orgWebsite,
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, React.createElement("img", {
                                  className: CssJs.style([
                                        CssJs.width({
                                              NAME: "vh",
                                              VAL: 25
                                            }),
                                        CssJs.height({
                                              NAME: "vh",
                                              VAL: 25
                                            }),
                                        CssJs.objectFit("contain")
                                      ]),
                                  src: orgImage
                                })), React.createElement("br", undefined), React.createElement("a", {
                              className: CnRe.fromList({
                                    hd: Styles.navListText,
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Css.em(3)),
                                            tl: /* [] */0
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              href: orgWebsite,
                              rel: "noopener noreferrer",
                              target: "_blank"
                            }, orgName), React.createElement("br", undefined), React.createElement("div", {
                              className: CssJs.style([
                                    CssJs.maxHeight({
                                          NAME: "em",
                                          VAL: 15
                                        }),
                                    CssJs.overflowY("auto"),
                                    CssJs.overflowX("hidden"),
                                    CssJs.backgroundColor(CssJs.rgb(240, 240, 240)),
                                    CssJs.padding(CssJs.em(0.5)),
                                    CssJs.borderRadius(CssJs.em(0.8))
                                  ])
                            }, tmp), React.createElement("br", undefined)), React.createElement(RimbleUi.Box, {
                          children: null,
                          width: [
                            1,
                            1,
                            0.3333
                          ],
                          className: Curry._1(Css.style, {
                                hd: Css.alignSelf(Css.center),
                                tl: {
                                  hd: Css.padding(Css.em(2)),
                                  tl: /* [] */0
                                }
                              })
                        }, optOrgYoutubeVid !== undefined ? React.createElement(make, {
                                videoCode: optOrgYoutubeVid
                              }) : null, React.createElement("h2", undefined, "Total Raised"), totalCollectedMainnetEth !== undefined && totalCollectMaticDai !== undefined ? React.createElement(Amounts.AmountRaised.make, {
                                mainnetEth: Caml_option.valFromOption(totalCollectedMainnetEth),
                                maticDai: Caml_option.valFromOption(totalCollectMaticDai)
                              }) : "Loading"), React.createElement(RimbleUi.Box, {
                          children: null,
                          width: [
                            1,
                            1,
                            0.3333
                          ],
                          className: Curry._1(Css.style, {
                                hd: Css.alignSelf(Css.center),
                                tl: {
                                  hd: Css.padding(Css.em(2)),
                                  tl: /* [] */0
                                }
                              })
                        }, orgAnimals.length !== 0 ? React.createElement(React.Fragment, {
                                children: null
                              }, React.createElement(RimbleUi.Heading, {
                                    children: "Organisation's animals"
                                  }), React.createElement(RimbleUi.Flex, {
                                    children: Belt_Array.map(orgAnimals, (function (animal) {
                                            var tokenId = Belt_Option.getWithDefault(animal.id, TokenId.makeFromInt(99999));
                                            return JsxPPXReactSupport.createElementWithKey(TokenId.toString(tokenId), UserProfile.Token.make, {
                                                        tokenId: tokenId
                                                      });
                                          })),
                                    flexWrap: "wrap",
                                    className: UserProfile.centreAlignOnMobile
                                  })) : React.createElement("p", undefined, "This organisation doesn't have any wildcards yet"), orgComingSoon.length !== 0 ? React.createElement(React.Fragment, {
                                children: null
                              }, React.createElement(RimbleUi.Heading, {
                                    children: "Coming soon"
                                  }), React.createElement(RimbleUi.Flex, {
                                    children: null,
                                    flexWrap: "wrap",
                                    className: UserProfile.centreAlignOnMobile
                                  }, null, Belt_Array.mapWithIndex(orgComingSoon, (function (key, animal) {
                                          return Belt_Option.mapWithDefault(Belt_Array.get(animal.real_wc_photos, 0), null, (function (photos) {
                                                        return JsxPPXReactSupport.createElementWithKey(String(key), OrgProfile$ComingSoonAnimal, {
                                                                    image: CONSTANTS.cdnBase + photos.image,
                                                                    onClick: (function (param) {
                                                                        setSelectedComingSoonAnimal(animal.key);
                                                                      })
                                                                  });
                                                      }));
                                        })))) : null))));
}

var OrgPage = {
  make: OrgProfile$OrgPage
};

function OrgProfile(props) {
  var orgId = props.orgId;
  var orgData = QlHooks.useLoadOrganisationQuery(orgId);
  return React.createElement(RimbleUi.Flex, {
              children: orgData !== undefined ? React.createElement(OrgProfile$OrgPage, {
                      orgData: orgData,
                      orgId: orgId,
                      selectedWildcardKey: props.selectedWildcardKey
                    }) : React.createElement("div", undefined, React.createElement(RimbleUi.Heading, {
                          children: "Loading Organisation Profile"
                        }), React.createElement(RimbleUi.Loader, {})),
              flexWrap: "wrap",
              alignItems: "center",
              className: Styles.topBody
            });
}

var make$1 = OrgProfile;

export {
  orgDescriptionArray_decode ,
  YoutubeVid ,
  ComingSoonAnimal ,
  ImageCarousel ,
  ComingSoonModal ,
  OrgPage ,
  make$1 as make,
}
/* make Not a pure module */
