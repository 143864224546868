// Generated by ReScript, PLEASE EDIT WITH CARE

import BnJs from "bn.js";

var secondsIn365DaysPrecisionScaled = new BnJs("31536000000000000000");

var secondsInAMonthBn = new BnJs("2592000");

var zeroBn = new BnJs("0");

var cdnBase = "https://dd2wadt5nc0o7.cloudfront.net";

var nullEthAddress = "0x0000000000000000000000000000000000000000";

var secondsInAMonth = 2592000;

export {
  cdnBase ,
  nullEthAddress ,
  secondsIn365DaysPrecisionScaled ,
  secondsInAMonth ,
  secondsInAMonthBn ,
  zeroBn ,
}
/* secondsIn365DaysPrecisionScaled Not a pure module */
