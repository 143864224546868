// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Moment from "moment";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";

function culculateUnitAmount(param, secondsInUnit, unitStr) {
  var timeStructure = param[1];
  var remainingSeconds = param[0];
  if (remainingSeconds < secondsInUnit) {
    return [
            remainingSeconds,
            timeStructure
          ];
  }
  var unitCount = Caml_int32.div(remainingSeconds, secondsInUnit);
  var remainingSeconds$1 = remainingSeconds - Math.imul(unitCount, secondsInUnit) | 0;
  return [
          remainingSeconds$1,
          Belt_List.add(timeStructure, {
                decimal: unitCount,
                unit: unitStr
              })
        ];
}

function calculateTimeRemainingTillHoursFromSeconds(numSeconds) {
  return culculateUnitAmount(culculateUnitAmount(culculateUnitAmount(culculateUnitAmount([
                          numSeconds,
                          /* [] */0
                        ], 31536000, "year"), 2628000, "month"), 86400, "day"), 3600, "hour");
}

function calculateTimeRemainingFromSeconds(numSeconds) {
  return culculateUnitAmount(culculateUnitAmount(calculateTimeRemainingTillHoursFromSeconds(numSeconds), 60, "minute"), 1, "second")[1];
}

function displayTimeLeft(durationStructure) {
  return Belt_List.reduce(durationStructure, "", (function (acc, item) {
                var display = String(item.decimal) + (" " + (item.unit + (
                      item.decimal > 1 ? "s " : " "
                    )));
                return display + acc;
              }));
}

function displayTimeLeftSimple(durationStructure) {
  return Belt_List.reduce(durationStructure, [
                "",
                ""
              ], (function (param, item) {
                  var display = String(item.decimal).padStart(0, 2);
                  return [
                          display + (param[1] + param[0]),
                          ":"
                        ];
                }))[0];
}

function displayTimeLeftHours(remainingTime) {
  var match = calculateTimeRemainingTillHoursFromSeconds(remainingTime);
  return displayTimeLeft(match[1]);
}

function calculateCountdown(endDateMoment) {
  return endDateMoment.diff(Moment(), "seconds") | 0;
}

function CountDown(props) {
  var displayUnits = props.displayUnits;
  var endDateMoment = props.endDateMoment;
  var displayUnits$1 = displayUnits !== undefined ? displayUnits : true;
  var match = React.useState(function () {
        return 0;
      });
  var setCountdown = match[1];
  var countDown = match[0];
  React.useEffect((function () {
          var date = calculateCountdown(endDateMoment);
          Curry._1(setCountdown, (function (param) {
                  return date;
                }));
          var interval = setInterval((function (param) {
                  var date = calculateCountdown(endDateMoment);
                  Curry._1(setCountdown, (function (param) {
                          return date;
                        }));
                }), 1000);
          return (function (param) {
                    clearInterval(interval);
                  });
        }), [
        endDateMoment,
        setCountdown
      ]);
  return React.createElement(React.Fragment, {
              children: displayUnits$1 ? displayTimeLeft(calculateTimeRemainingFromSeconds(countDown)) : displayTimeLeftSimple(calculateTimeRemainingFromSeconds(countDown))
            });
}

var secondsInAYear = 31536000;

var secondsInAMonth = 2628000;

var secondsInADay = 86400;

var secondsInAHour = 3600;

var secondsInAMinute = 60;

var make = CountDown;

export {
  culculateUnitAmount ,
  secondsInAYear ,
  secondsInAMonth ,
  secondsInADay ,
  secondsInAHour ,
  secondsInAMinute ,
  calculateTimeRemainingTillHoursFromSeconds ,
  calculateTimeRemainingFromSeconds ,
  displayTimeLeft ,
  displayTimeLeftSimple ,
  displayTimeLeftHours ,
  calculateCountdown ,
  make ,
}
/* react Not a pure module */
