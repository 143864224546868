// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Ethers from "ethers";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function unsafeToOption(unsafeFunc) {
  try {
    return Caml_option.some(Curry._1(unsafeFunc, undefined));
  }
  catch (raw__obj){
    var _obj = Caml_js_exceptions.internalToOCamlException(raw__obj);
    if (_obj.RE_EXN_ID === Js_exn.$$Error) {
      return ;
    }
    throw _obj;
  }
}

var Misc = {
  unsafeToOption: unsafeToOption
};

function makeAbi(abiArray) {
  return abiArray;
}

var BigNumber = {};

var Wallet = {};

var Providers = {};

function make(address, abi, providerSigner) {
  return new Ethers.Contract(address, abi, providerSigner._0);
}

var Contract = {
  make: make
};

function parseUnits(amount, unit) {
  return unsafeToOption(function (param) {
              return Ethers.utils.parseUnits(amount, unit);
            });
}

function parseEther(amount) {
  return parseUnits(amount, "ether");
}

function parseEtherUnsafe(amount) {
  return Ethers.utils.parseUnits(amount, "ether");
}

function getAddress(addressString) {
  return unsafeToOption(function (param) {
              return Ethers.utils.getAddress(addressString);
            });
}

function formatEther(__x) {
  return Ethers.utils.formatUnits(__x, "ether");
}

function formatEtherToPrecision(number, digits) {
  var digitMultiplier = Math.pow(10.0, digits);
  return String(Math.floor(Belt_Option.getExn(Belt_Float.fromString(Ethers.utils.formatUnits(number, "ether"))) * digitMultiplier) / digitMultiplier);
}

function ethAdrToStr(prim) {
  return prim;
}

function ethAdrToLowerStr(address) {
  return address.toLowerCase();
}

var Utils = {
  parseUnits: parseUnits,
  parseEther: parseEther,
  parseEtherUnsafe: parseEtherUnsafe,
  getAddress: getAddress,
  formatEther: formatEther,
  formatEtherToPrecision: formatEtherToPrecision,
  ethAdrToStr: ethAdrToStr,
  ethAdrToLowerStr: ethAdrToLowerStr
};

export {
  Misc ,
  makeAbi ,
  BigNumber ,
  Wallet ,
  Providers ,
  Contract ,
  Utils ,
}
/* ethers Not a pure module */
