// Generated by ReScript, PLEASE EDIT WITH CARE

import BnJs from "bn.js";
import * as React from "react";
import * as Helper from "./Helper.bs.js";
import * as Moment from "moment";
import * as QlHooks from "./QlHooks.bs.js";
import * as TokenId from "./TokenId.bs.js";
import * as MomentRe from "bs-moment/src/MomentRe.bs.js";
import * as CONSTANTS from "../CONSTANTS.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var getAnimal = TokenId.make;

function useAvatar(animal) {
  return Belt_Option.getWithDefault(Belt_Option.map(QlHooks.useWildcardAvatar(animal), (function (a) {
                    return CONSTANTS.cdnBase + a;
                  })), "./img/animals/comingsoon.png");
}

function useAlternateImage(animal) {
  return Belt_Option.flatMap(QlHooks.useRealImages(animal), (function (images) {
                return Belt_Array.get(images, 0);
              }));
}

function useGetOrgImage(org) {
  return Belt_Option.getWithDefault(Belt_Option.map(QlHooks.useLoadOrganisationLogo(org), (function (path) {
                    return CONSTANTS.cdnBase + path;
                  })), "https://dd2wadt5nc0o7.cloudfront.net/conservations/OGBage.png");
}

function useGetOrgBadge(org) {
  return Belt_Option.getWithDefault(Belt_Option.map(QlHooks.useLoadOrganisationLogo(org), (function (path) {
                    return CONSTANTS.cdnBase + path;
                  })), "https://dd2wadt5nc0o7.cloudfront.net/conservations/OGBage.png");
}

function useGetOrgBadgeImage(tokenId) {
  return useGetOrgBadge(Belt_Option.getWithDefault(QlHooks.useWildcardOrgId(tokenId), ""));
}

var nextLaunchDate = Moment.utc("2020-07-30T17:00:00");

function useIsLaunched(chain, animal) {
  var optLaunchTime = QlHooks.useLaunchTimeBN(chain, animal);
  var currentTime = QlHooks.useCurrentTimestampBn(undefined);
  var id = TokenId.toInt(animal);
  if (id === undefined) {
    return /* Launched */0;
  }
  if (id <= 29) {
    return /* Launched */0;
  }
  if (optLaunchTime === undefined) {
    return /* Loading */1;
  }
  var launchTime = Caml_option.valFromOption(optLaunchTime);
  if (launchTime.gt(currentTime)) {
    return /* LaunchDate */{
            _0: MomentRe.momentWithUnix(launchTime.toNumber())
          };
  } else {
    return /* Launched */0;
  }
}

function useTokenStatus(chain, animal) {
  var optLaunchTime = QlHooks.useLaunchTimeBN(chain, animal);
  var currentTime = QlHooks.useCurrentTimestampBn(undefined);
  var currentPriceWei = QlHooks.usePrice(chain, animal);
  if (optLaunchTime === undefined) {
    return /* Loading */0;
  }
  var launchTime = Caml_option.valFromOption(optLaunchTime);
  if (launchTime.gt(currentTime)) {
    return {
            TAG: /* WaitingForLaunch */0,
            _0: MomentRe.momentWithUnix(launchTime.toNumber())
          };
  }
  if (typeof currentPriceWei === "number") {
    return /* Loading */0;
  }
  if (currentPriceWei.TAG === /* Foreclosed */0) {
    return {
            TAG: /* Foreclosed */3,
            _0: Helper.bnToMoment(currentPriceWei._0)
          };
  }
  var price = currentPriceWei._0;
  if (price.gt(CONSTANTS.zeroBn)) {
    return {
            TAG: /* Owned */2,
            _0: price
          };
  } else {
    return {
            TAG: /* Launched */1,
            _0: MomentRe.momentWithUnix(launchTime.toNumber())
          };
  }
}

function useIsOnAuction(chain, animal) {
  var tokenStatus = useTokenStatus(chain, animal);
  if (typeof tokenStatus === "number" || tokenStatus.TAG !== /* Owned */2) {
    return true;
  } else {
    return false;
  }
}

function useAuctionPriceWei(chain, animal, launchTime) {
  var tokenStatus = useTokenStatus(chain, animal);
  var auctionStartPrice = QlHooks.useAuctionStartPrice(chain, animal);
  var auctionEndPrice = QlHooks.useAuctionEndPrice(chain, animal);
  var auctionLength = QlHooks.useAuctioLength(chain, animal);
  var currentTime = QlHooks.useCurrentTime(undefined);
  if (auctionStartPrice === undefined) {
    return ;
  }
  if (auctionEndPrice === undefined) {
    return ;
  }
  if (auctionLength === undefined) {
    return ;
  }
  var auctionLength$1 = Caml_option.valFromOption(auctionLength);
  var auctionEndPrice$1 = Caml_option.valFromOption(auctionEndPrice);
  var auctionStartPrice$1 = Caml_option.valFromOption(auctionStartPrice);
  var tmp;
  if (typeof tokenStatus === "number") {
    tmp = auctionEndPrice$1;
  } else {
    switch (tokenStatus.TAG | 0) {
      case /* Launched */1 :
          tmp = new BnJs(currentTime).lt(launchTime.add(auctionLength$1)) ? auctionStartPrice$1.sub(auctionStartPrice$1.sub(auctionEndPrice$1).mul(new BnJs(currentTime).sub(launchTime)).div(auctionLength$1)) : auctionEndPrice$1;
          break;
      case /* Foreclosed */3 :
          var auctionStartTime = new BnJs(tokenStatus._0.unix());
          tmp = new BnJs(currentTime).lt(auctionStartTime.add(auctionLength$1)) ? auctionStartPrice$1.sub(auctionStartPrice$1.sub(auctionEndPrice$1).mul(new BnJs(currentTime).sub(auctionStartTime)).div(auctionLength$1)) : auctionEndPrice$1;
          break;
      default:
        tmp = auctionEndPrice$1;
    }
  }
  return Caml_option.some(tmp);
}

function getChainIdFromAnimalId(animalId) {
  var a = Belt_Option.getWithDefault(TokenId.toInt(animalId), 0);
  if (a < 26 || a === 42) {
    return /* MainnetQuery */2;
  } else {
    return /* MaticQuery */1;
  }
}

function useChainIdFromAnimalId(animalId) {
  return React.useMemo((function () {
                return getChainIdFromAnimalId(animalId);
              }), [animalId]);
}

export {
  getAnimal ,
  useAvatar ,
  useAlternateImage ,
  useGetOrgImage ,
  useGetOrgBadge ,
  useGetOrgBadgeImage ,
  nextLaunchDate ,
  useIsLaunched ,
  useTokenStatus ,
  useIsOnAuction ,
  useAuctionPriceWei ,
  getChainIdFromAnimalId ,
  useChainIdFromAnimalId ,
}
/* nextLaunchDate Not a pure module */
