// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as RimbleUi from "rimble-ui";
import * as ReactSocialIcons from "@wildcards/react-social-icons";

var transparency_link = "https://blog.wildcards.world/where-is-the-money-going/";

var privacy_policy_link = "https://wildcards.world/privacy_policy.html";

var terms_and_conditions_link = "https://wildcards.world/terms_and_conditions.html";

var footer = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.hex("303030")),
      tl: {
        hd: Css.height(Css.vh(12)),
        tl: {
          hd: Css.media("(max-width: 831px)", {
                hd: Css.height(Css.vh(20)),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var footerWrapper = Curry._1(Css.style, {
      hd: Css.margin(Css.auto),
      tl: {
        hd: Css.maxWidth(Css.px(1080)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent(Css.spaceAround),
            tl: {
              hd: Css.alignItems(Css.center),
              tl: {
                hd: Css.flexWrap("wrap"),
                tl: {
                  hd: Css.height({
                        NAME: "percent",
                        VAL: 100
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var footerText = Curry._1(Css.style, {
      hd: Css.marginTop(Css.auto),
      tl: {
        hd: Css.marginBottom(Css.auto),
        tl: {
          hd: Css.color(Css.hex("919797")),
          tl: {
            hd: Css.media("(max-width: 831px)", {
                  hd: Css.width({
                        NAME: "percent",
                        VAL: 100
                      }),
                  tl: {
                    hd: Css.textAlign("center"),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var footerLink = Curry._1(Css.style, {
      hd: Css.textDecoration(Css.none),
      tl: {
        hd: Css.important(Css.color(Css.hex("919797"))),
        tl: /* [] */0
      }
    });

var footerSocialButtons = Curry._1(Css.style, {
      hd: Css.padding(Css.px(0)),
      tl: {
        hd: Css.media("(min-width: 831px)", {
              hd: Css.marginRight(Css.em(6)),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.listStyle("none", "inside", "none"),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.justifyContent(Css.center),
              tl: {
                hd: Css.alignItems(Css.center),
                tl: {
                  hd: Css.flexWrap(Css.wrap),
                  tl: {
                    hd: Css.media("(max-width: 831px)", {
                          hd: Css.width({
                                NAME: "percent",
                                VAL: 100
                              }),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var footerSocialButton = Curry._1(Css.style, /* [] */0);

var footerSocailButtonLink = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(32)),
      tl: {
        hd: Css.maxHeight(Css.px(32)),
        tl: /* [] */0
      }
    });

function Footer(props) {
  return React.createElement(RimbleUi.Box, {
              children: React.createElement("div", {
                    className: footerWrapper
                  }, React.createElement("div", {
                        className: footerText
                      }, React.createElement("div", undefined, "©", React.createElement("a", {
                                className: footerLink,
                                href: "https://wildcards.world",
                                rel: "noopener noreferrer",
                                target: "_blank"
                              }, "Wildcards"))), React.createElement("div", {
                        className: footerText
                      }, React.createElement("a", {
                            className: footerLink,
                            href: transparency_link,
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, "Transparency")), React.createElement("div", {
                        className: footerText
                      }, React.createElement("a", {
                            className: footerLink,
                            href: "/#team",
                            rel: "noopener noreferrer"
                          }, "Team")), React.createElement("div", {
                        className: footerText
                      }, React.createElement("a", {
                            className: footerLink,
                            href: privacy_policy_link,
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, "Privacy Policy")), React.createElement("div", {
                        className: footerText
                      }, React.createElement("a", {
                            className: footerLink,
                            href: terms_and_conditions_link,
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, "Terms and Conditions")), React.createElement("ul", {
                        className: footerSocialButtons
                      }, React.createElement("li", {
                            className: footerSocialButton
                          }, React.createElement(ReactSocialIcons.SocialIcon, {
                                url: "https://twitter.com/wildcards_world",
                                bgColor: "transparent",
                                fgColor: "#aaa",
                                target: "_blank",
                                className: footerSocailButtonLink,
                                rel: "noopener noreferrer",
                                network: "twitter"
                              })), React.createElement("li", {
                            className: footerSocialButton
                          }, React.createElement(ReactSocialIcons.SocialIcon, {
                                url: "https://www.facebook.com/wildcards.conservation",
                                bgColor: "transparent",
                                fgColor: "#aaa",
                                target: "_blank",
                                className: footerSocailButtonLink,
                                rel: "noopener noreferrer",
                                network: "facebook"
                              })), React.createElement("li", {
                            className: footerSocialButton
                          }, React.createElement(ReactSocialIcons.SocialIcon, {
                                url: "https://www.youtube.com/channel/UCW8T1lOHWs3klEJ36N9DyCA",
                                bgColor: "transparent",
                                fgColor: "#aaa",
                                target: "_blank",
                                className: footerSocailButtonLink,
                                rel: "noopener noreferrer",
                                network: "youtube"
                              })), React.createElement("li", {
                            className: footerSocialButton
                          }, React.createElement(ReactSocialIcons.SocialIcon, {
                                url: "https://github.com/wildcards-world",
                                bgColor: "transparent",
                                fgColor: "#aaa",
                                target: "_blank",
                                className: footerSocailButtonLink,
                                rel: "noopener noreferrer",
                                network: "github"
                              })), React.createElement("li", {
                            className: footerSocialButton
                          }, React.createElement(ReactSocialIcons.SocialIcon, {
                                url: "https://www.linkedin.com/company/wildcards-world/",
                                bgColor: "transparent",
                                fgColor: "#aaa",
                                target: "_blank",
                                className: footerSocailButtonLink,
                                rel: "noopener noreferrer",
                                network: "linkedin"
                              })))),
              className: footer
            });
}

var make = Footer;

export {
  transparency_link ,
  privacy_policy_link ,
  terms_and_conditions_link ,
  footer ,
  footerWrapper ,
  footerText ,
  footerLink ,
  footerSocialButtons ,
  footerSocialButton ,
  footerSocailButtonLink ,
  make ,
}
/* footer Not a pure module */
