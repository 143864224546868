// Generated by ReScript, PLEASE EDIT WITH CARE

import * as EthereumjsAbi from "ethereumjs-abi";
import * as EthereumjsUtil from "ethereumjs-util";

function getProviderOrSigner(library, account) {
  if (account !== undefined) {
    return library.getSigner(account);
  } else {
    return library;
  }
}

function constructMetaTransactionMessage(nonce, chainId, functionSignature, contractAddress) {
  return "0x" + EthereumjsAbi.soliditySHA3([
                "uint256",
                "address",
                "uint256",
                "bytes"
              ], [
                nonce,
                contractAddress,
                chainId,
                EthereumjsUtil.toBuffer(functionSignature)
              ]).toString("hex");
}

function getEthSig(sigString) {
  return {
          r: sigString.slice(0, 66),
          s: "0x" + sigString.slice(66, 130),
          v: parseInt(sigString.slice(130, 132), 16)
        };
}

export {
  getProviderOrSigner ,
  constructMetaTransactionMessage ,
  getEthSig ,
}
/* ethereumjs-abi Not a pure module */
