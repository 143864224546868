// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_promise from "rescript/lib/es6/js_promise.js";

global.Promise = require('bluebird')
;

Promise.config({
  warnings: false
})
;

function let_(p, cb) {
  return Js_promise.then_(cb, p);
}

function mapAsync(p, cb) {
  return Js_promise.then_((function (a) {
                return Promise.resolve(Curry._1(cb, a));
              }), p);
}

function async(a) {
  return Promise.resolve(a);
}

function catchAsync(p, cb) {
  return Js_promise.$$catch(cb, p);
}

function asyncFromResult(result) {
  return mapAsync(Promise.resolve(result), (function (a) {
                if (a.TAG === /* Ok */0) {
                  return a._0;
                } else {
                  return Js_exn.raiseError(a._0);
                }
              }));
}

function attemptMapAsync(promise, attempter) {
  return mapAsync(promise, (function (a) {
                var b = Curry._1(attempter, a);
                if (b.TAG === /* Ok */0) {
                  return b._0;
                } else {
                  return Js_exn.raiseError(b._0);
                }
              }));
}

export {
  let_ ,
  mapAsync ,
  async ,
  catchAsync ,
  asyncFromResult ,
  attemptMapAsync ,
}
/*  Not a pure module */
