// Generated by ReScript, PLEASE EDIT WITH CARE

import ThemeProviderJs from "./ThemeProvider.js";

var make = ThemeProviderJs;

export {
  make ,
}
/* make Not a pure module */
