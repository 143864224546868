// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CnRe from "rescript-classnames/src/CnRe.bs.js";
import * as CssJs from "bs-css-emotion/src/CssJs.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.bs.js";

var backgroundImageGorilla = "/img/wildcardsimages/wild-jungle-background.jpg";

CssJs.$$global("body", [
      CssJs.margin({
            NAME: "px",
            VAL: 0
          }),
      CssJs.fontFamily({
            NAME: "custom",
            VAL: "Lato, Helvetica, sans-serif"
          }),
      CssJs.overflowX(CssJs.hidden),
      CssJs.width({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.position("relative")
    ]);

CssJs.$$global("a", [
      CssJs.color(CssJs.hex("303030")),
      CssJs.textDecoration(CssJs.none)
    ]);

CssJs.$$global("a:hover", [CssJs.textDecoration(CssJs.underline)]);

CssJs.$$global("a:active", [CssJs.color(CssJs.hex("303030"))]);

var app = CssJs.style([
      CssJs.boxSizing("borderBox"),
      CssJs.fontSize(CssJs.px(16))
    ]);

var textOnlyModalText = CssJs.style([CssJs.padding(CssJs.em(2))]);

function totalRaisedText(size) {
  return CssJs.style([CssJs.fontSize(CssJs.em(size))]);
}

var copyButton = CssJs.style([
      CssJs.$$float("right"),
      CssJs.zIndex(50)
    ]);

var centerItems = CssJs.style([
      CssJs.display("flex"),
      CssJs.alignItems(CssJs.center),
      CssJs.width({
            NAME: "percent",
            VAL: 100
          })
    ]);

var centerItemsMargin = CssJs.style([
      CssJs.display(CssJs.block),
      CssJs.margin(CssJs.auto)
    ]);

var translationSwitch = CssJs.style([CssJs.padding(CssJs.rem(0))]);

var topBody = CssJs.style([
      CssJs.justifyContent("spaceBetween"),
      CssJs.media("(max-width: 831px)", [CssJs.textAlign(CssJs.center)]),
      CssJs.paddingLeft(CssJs.px(15)),
      CssJs.paddingRight(CssJs.px(15)),
      CssJs.maxWidth(CssJs.px(1200)),
      CssJs.margin(CssJs.auto)
    ]);

var header = CssJs.style([CssJs.position(CssJs.relative)]);

var noMarginBottom = CssJs.style([CssJs.marginBottom(CssJs.px(0))]);

var noMarginTop = CssJs.style([CssJs.marginTop(CssJs.px(0))]);

var headerLogo = CssJs.style([CssJs.media("(max-width: 630px)", [
            CssJs.textAlign(CssJs.center),
            CssJs.display("block")
          ])]);

var nav = CssJs.style([
      CssJs.position(CssJs.absolute),
      CssJs.maxWidth(CssJs.px(1200)),
      CssJs.zIndex(200),
      CssJs.top(CssJs.px(0)),
      CssJs.right(CssJs.px(0)),
      CssJs.left(CssJs.px(0)),
      CssJs.margin2(CssJs.em(0), CssJs.auto)
    ]);

var navBox = CssJs.style([
      CssJs.display("flex"),
      CssJs.justifyContent("spaceBetween"),
      CssJs.alignItems(CssJs.center)
    ]);

var navList = CssJs.style([
      CssJs.display("flex"),
      CssJs.alignItems("center"),
      CssJs.media("(max-width: 630px)", [CssJs.display(CssJs.none)]),
      CssJs.marginLeft("auto"),
      CssJs.listStyle("none", "inside", "none")
    ]);

var positionRelative = CssJs.style([CssJs.position(CssJs.relative)]);

function overlayImg(topPosition, leftPosition) {
  return CssJs.style([
              CssJs.position(CssJs.absolute),
              CssJs.zIndex(2),
              CssJs.top({
                    NAME: "percent",
                    VAL: topPosition
                  }),
              CssJs.left({
                    NAME: "percent",
                    VAL: leftPosition
                  }),
              CssJs.width({
                    NAME: "percent",
                    VAL: 20
                  }),
              CssJs.height({
                    NAME: "percent",
                    VAL: 20
                  }),
              CssJs.minWidth(CssJs.px(50)),
              CssJs.minHeight(CssJs.px(50))
            ]);
}

var overlayFlameImg = overlayImg(20, 0);

var prettyTransparent = CssJs.rgba(255, 255, 255, {
      NAME: "num",
      VAL: 0.5
    });

var imageHoverStyle = CssJs.hover([
      CssJs.filter([
            {
              NAME: "saturate",
              VAL: 150
            },
            {
              NAME: "brightness",
              VAL: 110
            }
          ]),
      CssJs.overflow(CssJs.visible),
      CssJs.backgroundColor(prettyTransparent),
      CssJs.boxShadow(Css_Js_Core.Shadow.box(undefined, undefined, CssJs.em(2), CssJs.em(2), undefined, prettyTransparent)),
      CssJs.transform(CssJs.scale(1.3, 1.3)),
      CssJs.transition(100, 0, CssJs.ease, "all")
    ]);

function overlayBadgeImg(x, y) {
  return CnRe.fromList({
              hd: overlayImg(x, y),
              tl: {
                hd: CssJs.style([
                      CssJs.borderRadius({
                            NAME: "percent",
                            VAL: 100
                          }),
                      imageHoverStyle
                    ]),
                tl: /* [] */0
              }
            });
}

var streakText = CssJs.style([
      CssJs.position(CssJs.absolute),
      CssJs.zIndex(100),
      CssJs.bottom({
            NAME: "percent",
            VAL: -30
          }),
      CssJs.right({
            NAME: "percent",
            VAL: 50
          }),
      CssJs.transform(CssJs.translateX({
                NAME: "percent",
                VAL: 50
              }))
    ]);

function smallImage(imageMaxWidth) {
  return CssJs.style([
              CssJs.width({
                    NAME: "percent",
                    VAL: 100
                  }),
              CssJs.maxWidth(imageMaxWidth)
            ]);
}

var flameImg = smallImage(CssJs.em(2.1));

var navListItem = CssJs.style([CssJs.display(CssJs.inlineBlock)]);

var navListItemToggle = CssJs.style([
      CssJs.display("flex"),
      CssJs.justifyContent(CssJs.center),
      CssJs.alignItems(CssJs.center)
    ]);

var someMarginRight = CssJs.style([CssJs.marginRight(CssJs.px(6))]);

var navListText = CssJs.style([
      CssJs.color(CssJs.rgb(136, 136, 136)),
      CssJs.padding(CssJs.rem(1)),
      CssJs.fontWeight(CssJs.bold)
    ]);

var leftTopHeader = CssJs.style([
      CssJs.position(CssJs.relative),
      CssJs.width(CssJs.px(550)),
      CssJs.maxWidth(CssJs.px(550)),
      CssJs.paddingTop(CssJs.px(70)),
      CssJs.paddingBottom(CssJs.px(70))
    ]);

var centerText = CssJs.style([CssJs.textAlign(CssJs.center)]);

var dappImagesCounteractOffset = CssJs.style([CssJs.marginLeft({
            NAME: "percent",
            VAL: 4
          })]);

var heading = CssJs.style([
      CssJs.fontSize(CssJs.em(3)),
      CssJs.media("(min-width: 768px)", [CssJs.paddingTop({
                  NAME: "rem",
                  VAL: 3
                })])
    ]);

var subHeading = CssJs.style([
      CssJs.fontSize(CssJs.em(1.8)),
      CssJs.fontWeight({
            NAME: "num",
            VAL: 200
          })
    ]);

var wildCardGreen = CssJs.rgb(107, 173, 62);

var wildCardBlue = CssJs.rgb(114, 199, 215);

var wildCardGrey = CssJs.rgb(100, 100, 100);

var colorGreen = CssJs.style([CssJs.color(wildCardGreen)]);

var colorBlue = CssJs.style([CssJs.color(wildCardBlue)]);

var colorGrey = CssJs.style([CssJs.color(wildCardGrey)]);

var animalBox = CssJs.style([CssJs.marginRight({
            NAME: "percent",
            VAL: 12
          })]);

var clickableLink = CssJs.style([
      CssJs.media("(max-width: 630px)", [CssJs.width({
                  NAME: "percent",
                  VAL: 100
                })]),
      CssJs.cursor("pointer")
    ]);

function mainImageHoverStyle(scalar) {
  return CssJs.hover([
              CssJs.filter([
                    {
                      NAME: "saturate",
                      VAL: 150
                    },
                    {
                      NAME: "brightness",
                      VAL: 110
                    }
                  ]),
              CssJs.zIndex(2),
              CssJs.overflow(CssJs.visible),
              CssJs.transform(CssJs.scale(1.1 * scalar, 1.1 * scalar)),
              CssJs.transition(100, 0, CssJs.ease, "all")
            ]);
}

function headerImg(enlargement, scalar) {
  return CssJs.style([
              CssJs.position("relative"),
              CssJs.zIndex(1),
              CssJs.maxHeight(CssJs.px(500)),
              CssJs.width({
                    NAME: "percent",
                    VAL: 100 * enlargement
                  }),
              CssJs.left({
                    NAME: "percent",
                    VAL: -50 * (enlargement - 1)
                  }),
              CssJs.transform(CssJs.scale(scalar, scalar)),
              CssJs.textAlign(CssJs.center),
              CssJs.transition(1000, 0, CssJs.ease, "all"),
              mainImageHoverStyle(scalar)
            ]);
}

var horizantalBlueTile = CssJs.style([
      CssJs.width({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.padding2(CssJs.em(2), CssJs.em(0)),
      CssJs.backgroundColor({
            NAME: "hex",
            VAL: "73c7d7ff"
          }),
      CssJs.textAlign("center")
    ]);

var explainerLargeText = CssJs.style([
      CssJs.fontSize(CssJs.rem(2)),
      CssJs.color({
            NAME: "hex",
            VAL: "486269"
          }),
      CssJs.padding2(CssJs.em(0), CssJs.em(6)),
      CssJs.margin2(CssJs.em(0), CssJs.auto),
      CssJs.maxWidth(CssJs.px(1200)),
      CssJs.media("(max-width: 760px)", [
            CssJs.fontSize(CssJs.rem(1.2)),
            CssJs.padding2(CssJs.em(0), CssJs.em(2))
          ])
    ]);

var explainerMediumText = CssJs.style([
      CssJs.media("(max-width: 760px)", [
            CssJs.fontSize(CssJs.rem(1.1)),
            CssJs.padding2(CssJs.em(0), CssJs.em(2))
          ]),
      CssJs.padding2(CssJs.em(0), CssJs.em(3)),
      CssJs.margin2(CssJs.em(0), CssJs.auto),
      CssJs.maxWidth(CssJs.px(1200)),
      CssJs.fontSize(CssJs.rem(1.6)),
      CssJs.color({
            NAME: "hex",
            VAL: "486269"
          }),
      CssJs.textAlign("center")
    ]);

var boldExplainerText = CssJs.style([
      CssJs.color({
            NAME: "hex",
            VAL: "303030"
          }),
      CssJs.fontWeight("bold")
    ]);

var infoBackground = CssJs.style([
      CssJs.backgrounds([
            {
              NAME: "linearGradient",
              VAL: [
                CssJs.deg(0),
                [
                  [
                    {
                      NAME: "percent",
                      VAL: 0
                    },
                    {
                      NAME: "rgba",
                      VAL: [
                        255,
                        255,
                        255,
                        {
                          NAME: "num",
                          VAL: 0.2
                        }
                      ]
                    }
                  ],
                  [
                    {
                      NAME: "percent",
                      VAL: 100
                    },
                    {
                      NAME: "rgba",
                      VAL: [
                        255,
                        255,
                        255,
                        {
                          NAME: "num",
                          VAL: 0.2
                        }
                      ]
                    }
                  ]
                ]
              ]
            },
            {
              NAME: "url",
              VAL: backgroundImageGorilla
            }
          ]),
      CssJs.backgroundSize("cover"),
      CssJs.backgroundRepeat("noRepeat"),
      CssJs.height({
            NAME: "percent",
            VAL: 100
          })
    ]);

var infoCardContainer = CssJs.style([CssJs.padding({
            NAME: "rem",
            VAL: 1
          })]);

var infoCardStyles = CssJs.style([
      CssJs.margin({
            NAME: "rem",
            VAL: 4
          }),
      CssJs.media("(max-width: 831px)", [
            CssJs.margin({
                  NAME: "rem",
                  VAL: 0
                }),
            CssJs.textAlign(CssJs.center)
          ])
    ]);

var animalImage = CssJs.style([CssJs.media("(max-width: 831px)", [CssJs.display(CssJs.none)])]);

var redDisclaimer = CssJs.style([
      CssJs.fontSize({
            NAME: "rem",
            VAL: 0.9
          }),
      CssJs.color({
            NAME: "hex",
            VAL: "e85723"
          })
    ]);

var floatingSignupBox = CssJs.style([
      CssJs.maxWidth(CssJs.px(900)),
      CssJs.margin2(CssJs.em(0), CssJs.auto)
    ]);

var floatingSignupBoxInner = CssJs.style([CssJs.padding(CssJs.em(3))]);

var emailSignupHeader = CssJs.style([CssJs.fontSize(CssJs.px(24))]);

var emailTextBox = CssJs.style([]);

var inputElements = CssJs.style([CssJs.padding(CssJs.em(0.1))]);

var extraInfoFooterBox = CssJs.style([
      CssJs.textAlign(CssJs.center),
      CssJs.maxWidth(CssJs.px(600)),
      CssJs.backgroundColor(CssJs.rgb(107, 173, 62)),
      CssJs.margin2(CssJs.em(6), CssJs.auto),
      CssJs.position(CssJs.relative)
    ]);

var emoticonHeader = CssJs.style([
      CssJs.position(CssJs.absolute),
      CssJs.left(CssJs.px(0)),
      CssJs.right(CssJs.px(0)),
      CssJs.top(CssJs.px(0)),
      CssJs.margin(CssJs.auto),
      CssJs.transform(CssJs.translateY({
                NAME: "percent",
                VAL: -50
              })),
      CssJs.textAlign(CssJs.center)
    ]);

var emojiStyles = CssJs.style([
      CssJs.height(CssJs.px(55)),
      CssJs.maxHeight({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.position(CssJs.relative),
      CssJs.verticalAlign(CssJs.middle),
      CssJs.bottom(CssJs.px(1))
    ]);

var finalNoteContent = CssJs.style([
      CssJs.padding(CssJs.rem(1.5)),
      CssJs.margin(CssJs.ex(4))
    ]);

var whiteText = CssJs.style([CssJs.important(CssJs.color(CssJs.white))]);

var linkPillBox = CssJs.style([
      CssJs.marginTop(CssJs.px(25)),
      CssJs.boxSizing(CssJs.borderBox),
      CssJs.minHeight(CssJs.px(25)),
      CssJs.fontSize(CssJs.px(14)),
      CssJs.borderRadius(CssJs.px(25)),
      CssJs.color(CssJs.hex("fff")),
      CssJs.backgroundColor(CssJs.rgba(48, 48, 48, {
                NAME: "num",
                VAL: 0.12
              }))
    ]);

var linkPillText = CssJs.style([
      CssJs.margin(CssJs.px(10)),
      CssJs.marginBottom(CssJs.px(1)),
      CssJs.color(CssJs.hex("fff"))
    ]);

var loginButton = CssJs.style([CssJs.paddingLeft({
            NAME: "px",
            VAL: 10
          })]);

var forwardBackButton = CssJs.style([
      CssJs.width({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.height({
            NAME: "em",
            VAL: 5
          })
    ]);

function mergeStyles(styles) {
  return Belt_List.reduce(styles, "", (function (prev, next) {
                return prev + (" " + next);
              }));
}

var wrapText = CssJs.style([
      CssJs.overflowWrap("breakWord"),
      CssJs.wordWrap("breakWord")
    ]);

var fiftyPercentWidth = CssJs.style([CssJs.width({
            NAME: "percent",
            VAL: 50
          })]);

var carousel = CssJs.style([
      CssJs.position("relative"),
      CssJs.width({
            NAME: "percent",
            VAL: 150
          }),
      CssJs.left({
            NAME: "percent",
            VAL: -25
          })
    ]);

var invisibleGorilla = CssJs.style([CssJs.display("none")]);

function fadeOut(targetOpacity) {
  return CssJs.style(Belt_Array.concat([
                  CssJs.width({
                        NAME: "percent",
                        VAL: 100
                      }),
                  CssJs.transition(2000, 0, CssJs.ease, "opacity")
                ], [CssJs.opacity(targetOpacity)]));
}

function carouselArrow(absolutePositionOpt, onLeft) {
  var absolutePosition = absolutePositionOpt !== undefined ? absolutePositionOpt : true;
  return CssJs.style(Belt_Array.concat([
                  CssJs.cursor("pointer"),
                  CssJs.padding({
                        NAME: "px",
                        VAL: 20
                      }),
                  CssJs.color(CssJs.white),
                  CssJs.backgroundColor(CssJs.hex("72c7d7")),
                  CssJs.hover(Belt_Array.concat([CssJs.backgroundColor(CssJs.hex("40b2c9"))], onLeft ? [
                              CssJs.paddingLeft({
                                    NAME: "px",
                                    VAL: 15
                                  }),
                              CssJs.paddingRight({
                                    NAME: "px",
                                    VAL: 25
                                  })
                            ] : [
                              CssJs.paddingRight({
                                    NAME: "px",
                                    VAL: 15
                                  }),
                              CssJs.paddingLeft({
                                    NAME: "px",
                                    VAL: 25
                                  })
                            ])),
                  CssJs.borderRadius(CssJs.px(4)),
                  CssJs.zIndex(3)
                ], absolutePosition ? [
                    CssJs.position(CssJs.absolute),
                    CssJs.transform(CssJs.translateX({
                              NAME: "percent",
                              VAL: -50
                            })),
                    onLeft ? CssJs.left({
                            NAME: "percent",
                            VAL: 20
                          }) : CssJs.left({
                            NAME: "percent",
                            VAL: 80
                          })
                  ] : []));
}

export {
  backgroundImageGorilla ,
  app ,
  textOnlyModalText ,
  totalRaisedText ,
  copyButton ,
  centerItems ,
  centerItemsMargin ,
  translationSwitch ,
  topBody ,
  header ,
  noMarginBottom ,
  noMarginTop ,
  headerLogo ,
  nav ,
  navBox ,
  navList ,
  positionRelative ,
  overlayImg ,
  overlayFlameImg ,
  prettyTransparent ,
  imageHoverStyle ,
  overlayBadgeImg ,
  streakText ,
  smallImage ,
  flameImg ,
  navListItem ,
  navListItemToggle ,
  someMarginRight ,
  navListText ,
  leftTopHeader ,
  centerText ,
  dappImagesCounteractOffset ,
  heading ,
  subHeading ,
  wildCardGreen ,
  wildCardBlue ,
  wildCardGrey ,
  colorGreen ,
  colorBlue ,
  colorGrey ,
  animalBox ,
  clickableLink ,
  mainImageHoverStyle ,
  headerImg ,
  horizantalBlueTile ,
  explainerLargeText ,
  explainerMediumText ,
  boldExplainerText ,
  infoBackground ,
  infoCardContainer ,
  infoCardStyles ,
  animalImage ,
  redDisclaimer ,
  floatingSignupBox ,
  floatingSignupBoxInner ,
  emailSignupHeader ,
  emailTextBox ,
  inputElements ,
  extraInfoFooterBox ,
  emoticonHeader ,
  emojiStyles ,
  finalNoteContent ,
  whiteText ,
  linkPillBox ,
  linkPillText ,
  loginButton ,
  forwardBackButton ,
  mergeStyles ,
  wrapText ,
  fiftyPercentWidth ,
  carousel ,
  invisibleGorilla ,
  fadeOut ,
  carouselArrow ,
}
/*  Not a pure module */
