// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "../../Styles.bs.js";
import * as RimbleUi from "rimble-ui";

function CustomerBenefit(props) {
  return React.createElement(RimbleUi.Box, {
              children: null,
              className: Styles.horizantalBlueTile
            }, React.createElement("p", {
                  className: Styles.explainerLargeText
                }, React.createElement("span", {
                      className: Styles.boldExplainerText
                    }, "Wildcards"), " connects global conservation organisations to users. "), React.createElement("p", {
                  className: Styles.explainerMediumText
                }, "Social, gamified and transparent giving makes wildcards the perfect platform to start making your difference."));
}

var make = CustomerBenefit;

export {
  make ,
}
/* react Not a pure module */
