// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Web3 from "../Web3.bs.js";
import Web3$1 from "web3";
import * as Async from "../Async.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import BnJs from "bn.js";
import * as React from "react";
import * as Ethers from "ethers";
import * as Globals from "../Globals.bs.js";
import * as $$Promise from "reason-promise/src/js/promise.bs.js";
import * as QlHooks from "../QlHooks.bs.js";
import * as TokenId from "../TokenId.bs.js";
import * as CONSTANTS from "../../CONSTANTS.bs.js";
import * as DaiPermit from "./DaiPermit.bs.js";
import * as Web3Utils from "../Web3Utils.bs.js";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContractUtil from "./ContractUtil.bs.js";
import * as RootProvider from "../RootProvider.bs.js";
import * as Core from "@web3-react/core";
import * as LoyaltyTokenJson from "./abi/loyaltyToken.json";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var loyaltyTokenAbi = LoyaltyTokenJson.loyaltyToken;

function getExchangeContract(stewardAddress, stewardAbi, library, account) {
  return new Ethers.Contract(stewardAddress, stewardAbi, ContractUtil.getProviderOrSigner(library, account));
}

function getLoyaltyTokenContract(stewardAddress, library, account) {
  return new Ethers.Contract(stewardAddress, loyaltyTokenAbi, ContractUtil.getProviderOrSigner(library, account));
}

var stewardAddressMainnet = "0x6D47CF86F6A490c6410fC082Fd1Ad29CF61492d0";

var stewardAddressGoerli = "0x0C00CFE8EbB34fE7C31d4915a43Cde211e9F0F3B";

var stewardAddressRinkeby = "0x229Cb219F056A9097b2744594Bc37597380854E8";

var loyaltyTokenAddressMainnet = "0x773c75c2277eD3e402BDEfd28Ec3b51A3AfbD8a4";

var loyaltyTokenAddressGoerli = "0xd7d8c42ab5b83aa3d4114e5297989dc27bdfb715";

function getDaiContractAddress(chain, chainId) {
  if (chain >= 2) {
    return "NEVER";
  } else if (chainId !== 5) {
    if (chainId !== 137) {
      if (chainId !== 80001) {
        return "0xba97BeC8d359D73c81D094421803D968A9FBf676";
      } else {
        return "0xeb37A6dF956F1997085498aDd98b25a2f633d83F";
      }
    } else {
      return "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";
    }
  } else {
    return "0xba97BeC8d359D73c81D094421803D968A9FBf676";
  }
}

function getStewardAddress(chain, chainId) {
  if (chain >= 2) {
    return "TODO";
  } else if (chainId !== 5) {
    if (chainId !== 137) {
      if (chainId !== 80001) {
        return "0xF26F8B2c178a0DeBB176c6b18e3F6d243fEEf828";
      } else {
        return "0xE44056eff470b1e505c3776601685c97A6966887";
      }
    } else {
      return "0x69895ba53B4CB7afaea2Ab519409F3d3C613a562";
    }
  } else {
    return "0xF26F8B2c178a0DeBB176c6b18e3F6d243fEEf828";
  }
}

function getMaticNetworkName(chainId) {
  if (chainId !== 5) {
    if (chainId !== 137) {
      if (chainId !== 80001) {
        return "goerli";
      } else {
        return "mumbai";
      }
    } else {
      return "matic";
    }
  } else {
    return "goerli";
  }
}

function getChildChainId(parentChainId) {
  if (parentChainId === 137) {
    return 137;
  }
  if (parentChainId >= 6) {
    if (parentChainId !== 80001) {
      return 5;
    } else {
      return 80001;
    }
  }
  if (parentChainId <= 0) {
    return 5;
  }
  switch (parentChainId) {
    case 1 :
        return 137;
    case 2 :
    case 3 :
    case 4 :
        return 5;
    case 5 :
        return 80001;
    
  }
}

function useStewardAbi(param) {
  var abi = RootProvider.useStewardAbi(undefined);
  if (abi !== undefined) {
    return Caml_option.valFromOption(abi);
  } else {
    return (require("./abi/steward.json").stewardAbi);
  }
}

function defaultStewardAddressFromChainId(x) {
  switch (x) {
    case 1 :
        return stewardAddressMainnet;
    case 2 :
    case 3 :
        return ;
    case 4 :
        return stewardAddressRinkeby;
    case 5 :
        return stewardAddressGoerli;
    default:
      return ;
  }
}

function useStewardAddress(param) {
  var externallySetAddress = RootProvider.useStewardContractAddress(undefined);
  return function (chainId) {
    return Belt_Option.mapWithDefault(externallySetAddress, defaultStewardAddressFromChainId(chainId), (function (a) {
                  return a;
                }));
  };
}

function loyaltyTokenAddressFromChainId(x) {
  if (x !== 1) {
    if (x !== 5) {
      return ;
    } else {
      return loyaltyTokenAddressGoerli;
    }
  } else {
    return loyaltyTokenAddressMainnet;
  }
}

function useStewardContract(param) {
  var context = Core.useWeb3React();
  var stewardContractAddress = useStewardAddress(undefined);
  var stewardAbi = useStewardAbi(undefined);
  return React.useMemo((function () {
                var match = context.library;
                var match$1 = context.chainId;
                if (match !== undefined && match$1 !== undefined) {
                  return Belt_Option.map(Curry._1(stewardContractAddress, match$1), (function (__x) {
                                return getExchangeContract(__x, stewardAbi, match, context.account);
                              }));
                }
                
              }), [
              context.library,
              context.account,
              context.chainId
            ]);
}

function useLoyaltyTokenContract(param) {
  var context = Core.useWeb3React();
  return React.useMemo((function () {
                var match = context.library;
                var match$1 = context.chainId;
                if (match !== undefined && match$1 !== undefined) {
                  return Belt_Option.map(loyaltyTokenAddressFromChainId(match$1), (function (__x) {
                                return getLoyaltyTokenContract(__x, match, context.account);
                              }));
                }
                
              }), [
              context.library,
              context.account,
              context.chainId
            ]);
}

var Raw = {};

var query = (require("@apollo/client").gql`
  mutation ($network: String!, $r: String!, $s: String!, $v: Int!, $userAddress: String!, $functionSignature: String!)  {
    metaTx(functionSignature: $functionSignature, network: $network, r: $r, s: $s, userAddress: $userAddress, v: $v)  {
      __typename
      txHash
      success
      errorMsg
    }
  }
`);

function parse(value) {
  var value$1 = value.metaTx;
  var value$2 = value$1.errorMsg;
  return {
          metaTx: {
            __typename: value$1.__typename,
            txHash: value$1.txHash,
            success: value$1.success,
            errorMsg: !(value$2 == null) ? value$2 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.metaTx;
  var value$2 = value$1.errorMsg;
  var errorMsg = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.success;
  var value$4 = value$1.txHash;
  var value$5 = value$1.__typename;
  var metaTx = {
    __typename: value$5,
    txHash: value$4,
    success: value$3,
    errorMsg: errorMsg
  };
  return {
          metaTx: metaTx
        };
}

function serializeVariables(inp) {
  return {
          network: inp.network,
          r: inp.r,
          s: inp.s,
          v: inp.v,
          userAddress: inp.userAddress,
          functionSignature: inp.functionSignature
        };
}

function makeVariables(network, r, s, v, userAddress, functionSignature, param) {
  return {
          network: network,
          r: r,
          s: s,
          v: v,
          userAddress: userAddress,
          functionSignature: functionSignature
        };
}

var ExecuteMetaTxMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var ExecuteMetaTxMutation_useWithVariables = include.useWithVariables;

var ExecuteMetaTxMutation = {
  ExecuteMetaTxMutation_inner: ExecuteMetaTxMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  use: use,
  useWithVariables: ExecuteMetaTxMutation_useWithVariables
};

function execDaiPermitMetaTx(daiNonce, networkName, stewardNonce, setTxState, sendMetaTx, userAddress, spender, lib, generateFunctionSignature, chainId, verifyingContract) {
  var __x = DaiPermit.createPermitSig(lib.provider, verifyingContract, daiNonce, chainId, userAddress, spender);
  var __x$1 = Js_promise.then_((function (rsvSig) {
          Curry._1(setTxState, (function (param) {
                  return /* SignMetaTx */1;
                }));
          var web3 = new Web3$1(lib.provider);
          var steward = Curry._2(Web3.Contract.MaticSteward.getStewardContract, web3, spender);
          var functionSignature = Curry._4(generateFunctionSignature, steward, rsvSig.v, rsvSig.r, rsvSig.s);
          var messageToSign = ContractUtil.constructMetaTransactionMessage(stewardNonce, chainId.toString(), functionSignature, spender);
          var __x = web3.eth.personal.sign(messageToSign, userAddress);
          return Js_promise.then_((function (signature) {
                        return Promise.resolve([
                                    functionSignature,
                                    signature
                                  ]);
                      }), __x);
        }), __x);
  var __x$2 = Js_promise.then_((function (param) {
          var match = ContractUtil.getEthSig(param[1]);
          return Promise.resolve(Curry._8(sendMetaTx, undefined, undefined, undefined, undefined, undefined, undefined, undefined, makeVariables(networkName, match.r, match.s, match.v, userAddress, param[0], undefined)));
        }), __x$1);
  Js_promise.$$catch((function (err) {
          console.log("this error was caught", err);
          return Promise.resolve("");
        }), __x$2);
}

function handleMetaTxSumbissionState(result, setTxState, currentState, setForceRefetch) {
  var networkId = RootProvider.useNetworkId(undefined);
  var data = result.data;
  if (!result.called) {
    return ;
  }
  if (data === undefined) {
    if (currentState === 3) {
      return ;
    } else {
      return Curry._1(setTxState, (function (param) {
                    return /* SubmittedMetaTx */3;
                  }));
    }
  }
  if (result.error !== undefined) {
    if (typeof currentState !== "number" && currentState.TAG === /* ServerError */3) {
      return ;
    }
    return Curry._1(setTxState, (function (param) {
                  return {
                          TAG: /* ServerError */3,
                          _0: "Query Error"
                        };
                }));
  }
  var success = data.metaTx.success;
  var errorMsg = data.metaTx.errorMsg;
  var txHash = data.metaTx.txHash;
  if (success) {
    if (typeof currentState === "number") {
      if (currentState === /* Failed */4) {
        return ;
      }
      
    } else {
      switch (currentState.TAG | 0) {
        case /* SignedAndSubmitted */1 :
        case /* Complete */4 :
            return ;
        default:
          
      }
    }
    Curry._1(setTxState, (function (param) {
            console.log("Setting the tx state!!!");
            return {
                    TAG: /* SignedAndSubmitted */1,
                    _0: txHash
                  };
          }));
    var providerUrl;
    if (networkId !== undefined) {
      switch (networkId) {
        case 1 :
            providerUrl = "https://rpc-mainnet.matic.network";
            break;
        case 2 :
        case 3 :
            providerUrl = "No Network";
            break;
        case 4 :
            providerUrl = "https://goerli.infura.io/v3/c401b8ee3a324619a453f2b5b2122d7a";
            break;
        case 5 :
            providerUrl = "https://rpc-mumbai.maticvigil.com/v1/bc87ce81f2f4695f31857297dc5458c336833a78";
            break;
        default:
          providerUrl = "No Network";
      }
    } else {
      providerUrl = "No Network";
    }
    var maticProvider = new (Ethers.providers.JsonRpcProvider)(providerUrl);
    var waitForTx = $$Promise.Js.toResult(maticProvider.waitForTransaction(txHash));
    $$Promise.getOk(waitForTx, (function (tx) {
            Curry._1(setTxState, (function (param) {
                    Curry._1(setForceRefetch, (function (param) {
                            return true;
                          }));
                    setTimeout((function (param) {
                            Curry._1(setForceRefetch, (function (param) {
                                    return true;
                                  }));
                          }), 3000);
                    return {
                            TAG: /* Complete */4,
                            _0: tx
                          };
                  }));
          }));
    return $$Promise.getError(waitForTx, (function (_error) {
                  Curry._1(setTxState, (function (param) {
                          return /* Failed */4;
                        }));
                }));
  }
  if (typeof currentState !== "number" && currentState.TAG === /* ServerError */3) {
    return ;
  }
  Curry._1(setTxState, (function (param) {
          return {
                  TAG: /* ServerError */3,
                  _0: Belt_Option.getWithDefault(errorMsg, "Unknown error")
                };
        }));
}

function useBuy(chain, animal, library, account, parentChainId) {
  var animalId = TokenId.toString(animal);
  var optSteward = useStewardContract(undefined);
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return /* UnInitialised */0;
      });
  var setTxState = match$1[1];
  var txState = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setForceRefreshData = match$2[1];
  var forceRefreshData = match$2[0];
  QlHooks.useWildcardQuery(chain, forceRefreshData, animal);
  React.useEffect((function () {
          Curry._1(setForceRefreshData, (function (param) {
                  return false;
                }));
        }), [forceRefreshData]);
  handleMetaTxSumbissionState(match[1], setTxState, txState, setForceRefreshData);
  var chainIdInt = getChildChainId(parentChainId);
  var chainId = new BnJs(chainIdInt);
  var verifyingContract = getDaiContractAddress(chain, chainIdInt);
  var spender = getStewardAddress(chain, chainIdInt);
  var networkName = getMaticNetworkName(chainIdInt);
  var match$3 = React.useState(function () {
        return chain === /* MaticQuery */1;
      });
  var setShouldRefreshNonce = match$3[1];
  var maticState = QlHooks.useMaticState(match$3[0], Belt_Option.getWithDefault(account, CONSTANTS.nullEthAddress), networkName);
  React.useEffect((function () {
          Curry._1(setShouldRefreshNonce, (function (param) {
                  return false;
                }));
        }), [
        account,
        networkName
      ]);
  if (chain >= 2) {
    return [
            (function (newPrice, oldPrice, wildcardsPercentage, value) {
                var newPriceEncoded = Ethers.utils.parseUnits(newPrice, 18);
                var value$1 = Ethers.utils.parseUnits(value, 0);
                var oldPriceParsed = Ethers.utils.parseUnits(oldPrice, 0);
                Curry._1(setTxState, (function (param) {
                        return /* Created */2;
                      }));
                if (optSteward === undefined) {
                  return ;
                }
                var buyPromise = $$Promise.Js.toResult(optSteward.buy(animalId, newPriceEncoded, oldPriceParsed, wildcardsPercentage, {
                          gasLimit: undefined,
                          value: value$1
                        }));
                $$Promise.getOk(buyPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* SignedAndSubmitted */1,
                                        _0: tx.hash
                                      };
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                Curry._1(setTxState, (function (param) {
                                        return {
                                                TAG: /* Complete */4,
                                                _0: txOutcome
                                              };
                                      }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */4;
                                      }));
                                console.log(error);
                              }));
                      }));
                $$Promise.getError(buyPromise, (function (error) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* Declined */2,
                                        _0: error.message
                                      };
                              }));
                      }));
              }),
            txState
          ];
  } else {
    return [
            (function (newPrice, oldPrice, wildcardsPercentage, value) {
                if (library === undefined) {
                  return ;
                }
                if (account === undefined) {
                  return ;
                }
                if (maticState === undefined) {
                  return ;
                }
                var daiNonce = maticState.daiNonce;
                var stewardNonce = maticState.stewardNonce;
                Curry._1(setTxState, (function (param) {
                        return {
                                TAG: /* DaiPermit */0,
                                _0: new BnJs(value)
                              };
                      }));
                execDaiPermitMetaTx(daiNonce, networkName, stewardNonce, setTxState, mutate, account, spender, library, (function (steward, v, r, s) {
                        return Curry._1(steward.methods.buyWithPermit(new BnJs(daiNonce), CONSTANTS.zeroBn, true, v, r, s, animalId, Web3Utils.toWeiFromEth(newPrice), oldPrice, wildcardsPercentage, value).encodeABI, undefined);
                      }), chainId, verifyingContract);
              }),
            txState
          ];
  }
}

function useBuyAuction(chain, animal, library, account, parentChainId) {
  var match = React.useState(function () {
        return /* UnInitialised */0;
      });
  var setTxState = match[1];
  var txState = match[0];
  var animalId = TokenId.toString(animal);
  var optSteward = useStewardContract(undefined);
  var match$1 = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setForceRefreshData = match$2[1];
  var forceRefreshData = match$2[0];
  QlHooks.useWildcardQuery(chain, forceRefreshData, animal);
  React.useEffect((function () {
          Curry._1(setForceRefreshData, (function (param) {
                  return false;
                }));
        }), [forceRefreshData]);
  handleMetaTxSumbissionState(match$1[1], setTxState, txState, setForceRefreshData);
  var chainIdInt = getChildChainId(parentChainId);
  var chainId = new BnJs(chainIdInt);
  var verifyingContract = getDaiContractAddress(chain, chainIdInt);
  var spender = getStewardAddress(chain, chainIdInt);
  var networkName = getMaticNetworkName(chainIdInt);
  var maticState = QlHooks.useMaticState(false, Belt_Option.getWithDefault(account, CONSTANTS.nullEthAddress), networkName);
  if (chain >= 2) {
    return [
            (function (newPrice, wildcardsPercentage, value) {
                var newPriceEncoded = Ethers.utils.parseUnits(newPrice, 18);
                var value$1 = Ethers.utils.parseUnits(value, 0);
                Curry._1(setTxState, (function (param) {
                        return /* Created */2;
                      }));
                if (optSteward === undefined) {
                  return ;
                }
                var buyPromise = $$Promise.Js.toResult(optSteward.buyAuction(animalId, newPriceEncoded, wildcardsPercentage, {
                          gasLimit: undefined,
                          value: value$1
                        }));
                $$Promise.getOk(buyPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* SignedAndSubmitted */1,
                                        _0: tx.hash
                                      };
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                Curry._1(setTxState, (function (param) {
                                        return {
                                                TAG: /* Complete */4,
                                                _0: txOutcome
                                              };
                                      }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */4;
                                      }));
                                console.log(error);
                              }));
                      }));
                $$Promise.getError(buyPromise, (function (error) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* Declined */2,
                                        _0: error.message
                                      };
                              }));
                      }));
              }),
            txState
          ];
  } else {
    return [
            (function (newPrice, wildcardsPercentage, value) {
                if (library !== undefined && account !== undefined && maticState !== undefined) {
                  var daiNonce = maticState.daiNonce;
                  var stewardNonce = maticState.stewardNonce;
                  Curry._1(setTxState, (function (param) {
                          return {
                                  TAG: /* DaiPermit */0,
                                  _0: new BnJs(value)
                                };
                        }));
                  return execDaiPermitMetaTx(daiNonce, networkName, stewardNonce, setTxState, mutate, account, spender, library, (function (steward, v, r, s) {
                                return Curry._1(steward.methods.buyAuctionWithPermit(new BnJs(daiNonce), CONSTANTS.zeroBn, true, v, r, s, animalId, Web3Utils.toWeiFromEth(newPrice), wildcardsPercentage, value).encodeABI, undefined);
                              }), chainId, verifyingContract);
                }
                console.log("something important is null");
                console.log(library, account, maticState);
              }),
            txState
          ];
  }
}

function useRedeemLoyaltyTokens(patron) {
  var match = React.useState(function () {
        return /* UnInitialised */0;
      });
  var setTxState = match[1];
  var optSteward = useStewardContract(undefined);
  var buyFunction = function (param) {
    var value = Ethers.utils.parseUnits("0", 0);
    Curry._1(setTxState, (function (param) {
            return /* Created */2;
          }));
    if (optSteward === undefined) {
      return ;
    }
    var claimLoyaltyTokenPromise = $$Promise.Js.toResult(optSteward._collectPatronagePatron(patron, {
              gasLimit: undefined,
              value: value
            }));
    $$Promise.getOk(claimLoyaltyTokenPromise, (function (tx) {
            Curry._1(setTxState, (function (param) {
                    return {
                            TAG: /* SignedAndSubmitted */1,
                            _0: tx.hash
                          };
                  }));
            var txMinedPromise = $$Promise.Js.toResult(tx.wait());
            $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                    console.log(txOutcome);
                    Curry._1(setTxState, (function (param) {
                            return {
                                    TAG: /* Complete */4,
                                    _0: txOutcome
                                  };
                          }));
                  }));
            $$Promise.getError(txMinedPromise, (function (error) {
                    Curry._1(setTxState, (function (param) {
                            return /* Failed */4;
                          }));
                    console.log(error);
                  }));
          }));
    $$Promise.getError(claimLoyaltyTokenPromise, (function (error) {
            Curry._1(setTxState, (function (param) {
                    return {
                            TAG: /* Declined */2,
                            _0: error.message
                          };
                  }));
          }));
  };
  return [
          buyFunction,
          match[0]
        ];
}

function useUpdateDeposit(useMetaTransactionOpt, chain, library, account, parentChainId) {
  var useMetaTransaction = useMetaTransactionOpt !== undefined ? useMetaTransactionOpt : false;
  var match = React.useState(function () {
        return /* UnInitialised */0;
      });
  var setTxState = match[1];
  var txState = match[0];
  var optSteward = useStewardContract(undefined);
  var match$1 = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setForceRefreshData = match$2[1];
  var forceRefreshData = match$2[0];
  React.useEffect((function () {
          Curry._1(setForceRefreshData, (function (param) {
                  return false;
                }));
        }), [forceRefreshData]);
  QlHooks.useQueryPatronQuery(chain, forceRefreshData, Belt_Option.getWithDefault(account, ""));
  handleMetaTxSumbissionState(match$1[1], setTxState, txState, setForceRefreshData);
  var chainIdInt = getChildChainId(parentChainId);
  var chainId = new BnJs(chainIdInt);
  var verifyingContract = getDaiContractAddress(chain, chainIdInt);
  var spender = getStewardAddress(chain, chainIdInt);
  var networkName = getMaticNetworkName(chainIdInt);
  var maticState = QlHooks.useMaticState(false, Belt_Option.getWithDefault(account, CONSTANTS.nullEthAddress), networkName);
  if (chain >= 2) {
    return [
            (function (value) {
                var value$1 = Ethers.utils.parseUnits(value, 0);
                Curry._1(setTxState, (function (param) {
                        return /* Created */2;
                      }));
                if (optSteward === undefined) {
                  return ;
                }
                var updateDepositPromise = $$Promise.Js.toResult(optSteward.depositWei({
                          gasLimit: undefined,
                          value: value$1
                        }));
                $$Promise.getOk(updateDepositPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* SignedAndSubmitted */1,
                                        _0: tx.hash
                                      };
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                Curry._1(setTxState, (function (param) {
                                        return {
                                                TAG: /* Complete */4,
                                                _0: txOutcome
                                              };
                                      }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (_error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */4;
                                      }));
                              }));
                      }));
                $$Promise.getError(updateDepositPromise, (function (error) {
                        console.log("error processing transaction: " + error.message);
                      }));
              }),
            txState
          ];
  } else {
    return [
            (function (amountToAdd) {
                if (library !== undefined && account !== undefined && maticState !== undefined) {
                  var daiNonce = maticState.daiNonce;
                  var stewardNonce = maticState.stewardNonce;
                  if (useMetaTransaction) {
                    Curry._1(setTxState, (function (param) {
                            return {
                                    TAG: /* DaiPermit */0,
                                    _0: new BnJs(amountToAdd)
                                  };
                          }));
                    return execDaiPermitMetaTx(daiNonce, networkName, stewardNonce, setTxState, mutate, account, spender, library, (function (steward, v, r, s) {
                                  return Curry._1(steward.methods.depositWithPermit(new BnJs(daiNonce), CONSTANTS.zeroBn, true, v, r, s, account, new BnJs(amountToAdd)).encodeABI, undefined);
                                }), chainId, verifyingContract);
                  } else {
                    return ;
                  }
                }
                console.log("something important is null");
                console.log(library, account, maticState);
              }),
            txState
          ];
  }
}

function useWithdrawDeposit(chain, library, account, parentChainId) {
  var match = React.useState(function () {
        return /* UnInitialised */0;
      });
  var setTxState = match[1];
  var txState = match[0];
  var optSteward = useStewardContract(undefined);
  var chainIdInt = getChildChainId(parentChainId);
  var spender = getStewardAddress(chain, chainIdInt);
  if (chain >= 2) {
    return [
            (function (amountToWithdraw) {
                var value = Ethers.utils.parseUnits("0", 0);
                var amountToWithdrawEncoded = Ethers.utils.parseUnits(amountToWithdraw, 0);
                Curry._1(setTxState, (function (param) {
                        return /* Created */2;
                      }));
                if (optSteward === undefined) {
                  return ;
                }
                var updateDepositPromise = $$Promise.Js.toResult(optSteward.withdrawDeposit(amountToWithdrawEncoded, {
                          gasLimit: undefined,
                          value: value
                        }));
                $$Promise.getOk(updateDepositPromise, (function (tx) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* SignedAndSubmitted */1,
                                        _0: tx.hash
                                      };
                              }));
                        var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                        $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                                console.log(txOutcome);
                                Curry._1(setTxState, (function (param) {
                                        return {
                                                TAG: /* Complete */4,
                                                _0: txOutcome
                                              };
                                      }));
                              }));
                        $$Promise.getError(txMinedPromise, (function (error) {
                                Curry._1(setTxState, (function (param) {
                                        return /* Failed */4;
                                      }));
                                console.log(error);
                              }));
                      }));
                $$Promise.getError(updateDepositPromise, (function (error) {
                        Curry._1(setTxState, (function (param) {
                                return {
                                        TAG: /* Declined */2,
                                        _0: error.message
                                      };
                              }));
                      }));
              }),
            txState
          ];
  } else {
    return [
            (function (amountToWithdraw) {
                if (library === undefined) {
                  return ;
                }
                if (account === undefined) {
                  return ;
                }
                var web3 = new Web3$1(library.provider);
                var steward = Curry._2(Web3.Contract.MaticSteward.getStewardContract, web3, spender);
                var __x = Curry._1(steward.methods.withdrawDeposit(amountToWithdraw).send, {
                      from: account
                    });
                Js_promise.$$catch((function (err) {
                        console.log("this error was caught", err);
                        return Promise.resolve("");
                      }), __x);
              }),
            txState
          ];
  }
}

function useUserLoyaltyTokenBalance(address) {
  var match = React.useState(function () {
        
      });
  var setResult = match[1];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setCounter = match$1[1];
  var counter = match$1[0];
  var optSteward = useLoyaltyTokenContract(undefined);
  React.useEffect((function () {
          if (optSteward !== undefined) {
            Async.let_(optSteward.balanceOf(address), (function (balance) {
                    var balanceString = balance.toString();
                    Curry._1(setResult, (function (param) {
                            return Caml_option.some(new BnJs(balanceString));
                          }));
                    return Globals.async(undefined);
                  }));
          }
          
        }), [
        counter,
        setResult,
        optSteward,
        address
      ]);
  return [
          match[0],
          (function (param) {
              Curry._1(setCounter, (function (param) {
                      return counter + 1 | 0;
                    }));
            })
        ];
}

function useChangePrice(animal) {
  var animalId = TokenId.toString(animal);
  var match = React.useState(function () {
        return /* UnInitialised */0;
      });
  var setTxState = match[1];
  var optSteward = useStewardContract(undefined);
  return [
          (function (newPrice) {
              var value = Ethers.utils.parseUnits("0", 0);
              var newPriceEncoded = Ethers.utils.parseUnits(newPrice, 0);
              Curry._1(setTxState, (function (param) {
                      return /* Created */2;
                    }));
              if (optSteward === undefined) {
                return ;
              }
              var updatePricePromise = $$Promise.Js.toResult(optSteward.changePrice(animalId, newPriceEncoded, {
                        gasLimit: undefined,
                        value: value
                      }));
              $$Promise.getOk(updatePricePromise, (function (tx) {
                      Curry._1(setTxState, (function (param) {
                              return {
                                      TAG: /* SignedAndSubmitted */1,
                                      _0: tx.hash
                                    };
                            }));
                      var txMinedPromise = $$Promise.Js.toResult(tx.wait());
                      $$Promise.getOk(txMinedPromise, (function (txOutcome) {
                              console.log(txOutcome);
                              Curry._1(setTxState, (function (param) {
                                      return {
                                              TAG: /* Complete */4,
                                              _0: txOutcome
                                            };
                                    }));
                            }));
                      $$Promise.getError(txMinedPromise, (function (error) {
                              Curry._1(setTxState, (function (param) {
                                      return /* Failed */4;
                                    }));
                              console.log(error);
                            }));
                    }));
              $$Promise.getError(updatePricePromise, (function (error) {
                      Curry._1(setTxState, (function (param) {
                              return {
                                      TAG: /* Declined */2,
                                      _0: error.message
                                    };
                            }));
                    }));
            }),
          match[0]
        ];
}

var stewardAddressMaticMain = "0x6D47CF86F6A490c6410fC082Fd1Ad29CF61492d0";

var stewardAddressMumbai = "0x0C00CFE8EbB34fE7C31d4915a43Cde211e9F0F3B";

var loyaltyTokenAddressMaticMain = "0x773c75c2277eD3e402BDEfd28Ec3b51A3AfbD8a4";

var loyaltyTokenAddressMumbai = "0xd7d8c42ab5b83aa3d4114e5297989dc27bdfb715";

export {
  loyaltyTokenAbi ,
  getExchangeContract ,
  getLoyaltyTokenContract ,
  stewardAddressMainnet ,
  stewardAddressGoerli ,
  stewardAddressRinkeby ,
  loyaltyTokenAddressMainnet ,
  loyaltyTokenAddressGoerli ,
  stewardAddressMaticMain ,
  stewardAddressMumbai ,
  loyaltyTokenAddressMaticMain ,
  loyaltyTokenAddressMumbai ,
  getDaiContractAddress ,
  getStewardAddress ,
  getMaticNetworkName ,
  getChildChainId ,
  useStewardAbi ,
  defaultStewardAddressFromChainId ,
  useStewardAddress ,
  loyaltyTokenAddressFromChainId ,
  useStewardContract ,
  useLoyaltyTokenContract ,
  ExecuteMetaTxMutation ,
  execDaiPermitMetaTx ,
  handleMetaTxSumbissionState ,
  useBuy ,
  useBuyAuction ,
  useRedeemLoyaltyTokens ,
  useUpdateDeposit ,
  useWithdrawDeposit ,
  useUserLoyaltyTokenBalance ,
  useChangePrice ,
}
/* loyaltyTokenAbi Not a pure module */
