// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AbisJson from "./abis/abis.json";

var getContract = ((web3, abi, contractAddress) => new web3.eth.Contract(abi, contractAddress));

var stewardMaticAbi = AbisJson.stewardMatic;

function getStewardContract(web3, address) {
  return getContract(web3, stewardMaticAbi, address);
}

var MaticSteward = {
  stewardMaticAbi: stewardMaticAbi,
  getStewardContract: getStewardContract
};

var Contract = {
  getContract: getContract,
  MaticSteward: MaticSteward
};

export {
  Contract ,
}
/* stewardMaticAbi Not a pure module */
