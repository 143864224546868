// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.bs.js";
import BnJs from "bn.js";
import * as React from "react";
import * as Helper from "./Helper.bs.js";
import * as Moment from "moment";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as TokenId from "./TokenId.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as CONSTANTS from "../CONSTANTS.bs.js";
import * as Web3Utils from "./Web3Utils.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RootProvider from "./RootProvider.bs.js";
import * as GqlConverters from "../gql/GqlConverters.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  fragment BlockchainWildcardData on Wildcard   {
    __typename
    id
    animal: tokenId
    owner  {
      __typename
      id
      address
    }
    price  {
      __typename
      price
      id
    }
    totalCollected
    timeCollected
    patronageNumerator
    patronageNumeratorPriceScaled
    timeAcquired
    auctionStartPrice
    launchTime
  }
`);

function parse(value) {
  var value$1 = value.owner;
  var value$2 = value.price;
  var value$3 = value.auctionStartPrice;
  return {
          __typename: value.__typename,
          id: value.id,
          animal: GqlConverters.GqlTokenId.parse(value.animal),
          owner: {
            __typename: value$1.__typename,
            id: value$1.id,
            address: GqlConverters.GqlAddress.parse(value$1.address)
          },
          price: {
            __typename: value$2.__typename,
            price: GqlConverters.Price.parse(value$2.price),
            id: value$2.id
          },
          totalCollected: GqlConverters.Price.parse(value.totalCollected),
          timeCollected: GqlConverters.$$BigInt.parse(value.timeCollected),
          patronageNumerator: GqlConverters.$$BigInt.parse(value.patronageNumerator),
          patronageNumeratorPriceScaled: GqlConverters.$$BigInt.parse(value.patronageNumeratorPriceScaled),
          timeAcquired: GqlConverters.GqlMoment.parse(value.timeAcquired),
          auctionStartPrice: !(value$3 == null) ? Caml_option.some(GqlConverters.$$BigInt.parse(value$3)) : undefined,
          launchTime: GqlConverters.$$BigInt.parse(value.launchTime)
        };
}

function serialize(value) {
  var value$1 = value.launchTime;
  var value$2 = GqlConverters.$$BigInt.serialize(value$1);
  var value$3 = value.auctionStartPrice;
  var auctionStartPrice = value$3 !== undefined ? GqlConverters.$$BigInt.serialize(Caml_option.valFromOption(value$3)) : null;
  var value$4 = value.timeAcquired;
  var value$5 = GqlConverters.GqlMoment.serialize(value$4);
  var value$6 = value.patronageNumeratorPriceScaled;
  var value$7 = GqlConverters.$$BigInt.serialize(value$6);
  var value$8 = value.patronageNumerator;
  var value$9 = GqlConverters.$$BigInt.serialize(value$8);
  var value$10 = value.timeCollected;
  var value$11 = GqlConverters.$$BigInt.serialize(value$10);
  var value$12 = value.totalCollected;
  var value$13 = GqlConverters.Price.serialize(value$12);
  var value$14 = value.price;
  var value$15 = value$14.id;
  var value$16 = value$14.price;
  var value$17 = GqlConverters.Price.serialize(value$16);
  var value$18 = value$14.__typename;
  var price = {
    __typename: value$18,
    price: value$17,
    id: value$15
  };
  var value$19 = value.owner;
  var value$20 = value$19.address;
  var value$21 = GqlConverters.GqlAddress.serialize(value$20);
  var value$22 = value$19.id;
  var value$23 = value$19.__typename;
  var owner = {
    __typename: value$23,
    id: value$22,
    address: value$21
  };
  var value$24 = value.animal;
  var value$25 = GqlConverters.GqlTokenId.serialize(value$24);
  var value$26 = value.id;
  var value$27 = value.__typename;
  return {
          __typename: value$27,
          id: value$26,
          animal: value$25,
          owner: owner,
          price: price,
          totalCollected: value$13,
          timeCollected: value$11,
          patronageNumerator: value$9,
          patronageNumeratorPriceScaled: value$7,
          timeAcquired: value$5,
          auctionStartPrice: auctionStartPrice,
          launchTime: value$2
        };
}

function verifyArgsAndParse(_BlockchainWildcardData, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var BlockchainWildcardData = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  fragment WildcardMainData on wildcardData   {
    __typename
    id
    key
    name
    commonName
    description
    organization  {
      __typename
      name
      id
      logo
    }
    image
    real_wc_photos  {
      __typename
      image
      photographer
    }
    artistOfWildcard  {
      __typename
      name
      id
    }
  }
`);

function parse$1(value) {
  var value$1 = value.id;
  var value$2 = value.name;
  var value$3 = value.commonName;
  var value$4 = value.organization;
  var value$5 = value.image;
  var value$6 = value.real_wc_photos;
  var value$7 = value.artistOfWildcard;
  return {
          __typename: value.__typename,
          id: !(value$1 == null) ? value$1 : undefined,
          key: value.key,
          name: !(value$2 == null) ? value$2 : undefined,
          commonName: !(value$3 == null) ? value$3 : undefined,
          description: value.description,
          organization: !(value$4 == null) ? ({
                __typename: value$4.__typename,
                name: value$4.name,
                id: value$4.id,
                logo: value$4.logo
              }) : undefined,
          image: !(value$5 == null) ? value$5 : undefined,
          real_wc_photos: Js_array.map((function (value) {
                  var value$1 = value.photographer;
                  return {
                          __typename: value.__typename,
                          image: value.image,
                          photographer: !(value$1 == null) ? value$1 : undefined
                        };
                }), value$6),
          artistOfWildcard: !(value$7 == null) ? ({
                __typename: value$7.__typename,
                name: value$7.name,
                id: value$7.id
              }) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.artistOfWildcard;
  var artistOfWildcard;
  if (value$1 !== undefined) {
    var value$2 = value$1.id;
    var value$3 = value$1.name;
    var value$4 = value$1.__typename;
    artistOfWildcard = {
      __typename: value$4,
      name: value$3,
      id: value$2
    };
  } else {
    artistOfWildcard = null;
  }
  var value$5 = value.real_wc_photos;
  var real_wc_photos = Js_array.map((function (value) {
          var value$1 = value.photographer;
          var photographer = value$1 !== undefined ? value$1 : null;
          var value$2 = value.image;
          var value$3 = value.__typename;
          return {
                  __typename: value$3,
                  image: value$2,
                  photographer: photographer
                };
        }), value$5);
  var value$6 = value.image;
  var image = value$6 !== undefined ? value$6 : null;
  var value$7 = value.organization;
  var organization;
  if (value$7 !== undefined) {
    var value$8 = value$7.logo;
    var value$9 = value$7.id;
    var value$10 = value$7.name;
    var value$11 = value$7.__typename;
    organization = {
      __typename: value$11,
      name: value$10,
      id: value$9,
      logo: value$8
    };
  } else {
    organization = null;
  }
  var value$12 = value.description;
  var value$13 = value.commonName;
  var commonName = value$13 !== undefined ? value$13 : null;
  var value$14 = value.name;
  var name = value$14 !== undefined ? value$14 : null;
  var value$15 = value.key;
  var value$16 = value.id;
  var id = value$16 !== undefined ? value$16 : null;
  var value$17 = value.__typename;
  return {
          __typename: value$17,
          id: id,
          key: value$15,
          name: name,
          commonName: commonName,
          description: value$12,
          organization: organization,
          image: image,
          real_wc_photos: real_wc_photos,
          artistOfWildcard: artistOfWildcard
        };
}

function verifyArgsAndParse$1(_WildcardMainData, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var WildcardMainData = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

var query$2 = ((frag_0) => require("@apollo/client").gql`
  query ($amount: Int!, $globalId: String!)  {
    wildcards(first: $amount)  {
      ...BlockchainWildcardData
    }
    global(id: $globalId)  {
      __typename
      id
      totalCollectedOrDueAccurate
      timeLastCollected
      totalTokenCostScaledNumeratorAccurate
      defaultAuctionLength
      defaultAuctionEndPrice
      defaultAuctionStartPrice
    }
  }
  ${frag_0}
`)(query);

function parse$2(value) {
  var value$1 = value.wildcards;
  var value$2 = value.global;
  return {
          wildcards: Js_array.map(parse, value$1),
          global: !(value$2 == null) ? ({
                __typename: value$2.__typename,
                id: value$2.id,
                totalCollectedOrDueAccurate: GqlConverters.$$BigInt.parse(value$2.totalCollectedOrDueAccurate),
                timeLastCollected: GqlConverters.$$BigInt.parse(value$2.timeLastCollected),
                totalTokenCostScaledNumeratorAccurate: GqlConverters.$$BigInt.parse(value$2.totalTokenCostScaledNumeratorAccurate),
                defaultAuctionLength: GqlConverters.$$BigInt.parse(value$2.defaultAuctionLength),
                defaultAuctionEndPrice: GqlConverters.$$BigInt.parse(value$2.defaultAuctionEndPrice),
                defaultAuctionStartPrice: GqlConverters.$$BigInt.parse(value$2.defaultAuctionStartPrice)
              }) : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.global;
  var $$global;
  if (value$1 !== undefined) {
    var value$2 = value$1.defaultAuctionStartPrice;
    var value$3 = GqlConverters.$$BigInt.serialize(value$2);
    var value$4 = value$1.defaultAuctionEndPrice;
    var value$5 = GqlConverters.$$BigInt.serialize(value$4);
    var value$6 = value$1.defaultAuctionLength;
    var value$7 = GqlConverters.$$BigInt.serialize(value$6);
    var value$8 = value$1.totalTokenCostScaledNumeratorAccurate;
    var value$9 = GqlConverters.$$BigInt.serialize(value$8);
    var value$10 = value$1.timeLastCollected;
    var value$11 = GqlConverters.$$BigInt.serialize(value$10);
    var value$12 = value$1.totalCollectedOrDueAccurate;
    var value$13 = GqlConverters.$$BigInt.serialize(value$12);
    var value$14 = value$1.id;
    var value$15 = value$1.__typename;
    $$global = {
      __typename: value$15,
      id: value$14,
      totalCollectedOrDueAccurate: value$13,
      timeLastCollected: value$11,
      totalTokenCostScaledNumeratorAccurate: value$9,
      defaultAuctionLength: value$7,
      defaultAuctionEndPrice: value$5,
      defaultAuctionStartPrice: value$3
    };
  } else {
    $$global = null;
  }
  var value$16 = value.wildcards;
  var wildcards = Js_array.map(serialize, value$16);
  return {
          wildcards: wildcards,
          global: $$global
        };
}

function serializeVariables(inp) {
  return {
          amount: inp.amount,
          globalId: inp.globalId
        };
}

function makeVariables(amount, globalId, param) {
  return {
          amount: amount,
          globalId: globalId
        };
}

var InitialLoad_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables
    });

var use = include.use;

var InitialLoad_refetchQueryDescription = include.refetchQueryDescription;

var InitialLoad_useLazy = include.useLazy;

var InitialLoad_useLazyWithVariables = include.useLazyWithVariables;

var InitialLoad = {
  InitialLoad_inner: InitialLoad_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: InitialLoad_refetchQueryDescription,
  use: use,
  useLazy: InitialLoad_useLazy,
  useLazyWithVariables: InitialLoad_useLazyWithVariables
};

function createContext(prim) {
  return prim;
}

function chainToContext(chain) {
  return {
          context: chain
        };
}

function useInitialDataLoad(chain) {
  var initLoadQuery = Curry.app(use, [
        undefined,
        {
          context: chain
        },
        undefined,
        undefined,
        /* CacheFirst */1,
        undefined,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          amount: chain !== 1 ? 30 : 61,
          globalId: chain !== 1 ? "1" : "Matic-Global"
        }
      ]);
  if (initLoadQuery.loading || initLoadQuery.error !== undefined) {
    return ;
  } else {
    return initLoadQuery.data;
  }
}

function useAnimalList(chain) {
  var allData = useInitialDataLoad(chain);
  return React.useMemo((function () {
                return Belt_Option.getWithDefault(Belt_Option.map(allData, (function (data) {
                                  return Belt_Array.map(data.wildcards, (function (wc) {
                                                return wc.animal;
                                              }));
                                })), []);
              }), [
              allData,
              chain
            ]);
}

var Raw$3 = {};

var query$3 = ((frag_0) => require("@apollo/client").gql`
  query ($tokenId: String!)  {
    wildcard(id: $tokenId)  {
      ...BlockchainWildcardData
    }
  }
  ${frag_0}
`)(query);

function parse$3(value) {
  var value$1 = value.wildcard;
  return {
          wildcard: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$3(value) {
  var value$1 = value.wildcard;
  var wildcard = value$1 !== undefined ? serialize(value$1) : null;
  return {
          wildcard: wildcard
        };
}

function serializeVariables$1(inp) {
  return {
          tokenId: inp.tokenId
        };
}

function makeVariables$1(tokenId, param) {
  return {
          tokenId: tokenId
        };
}

var WildcardChainDataQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$3,
      Raw: Raw$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var WildcardChainDataQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var WildcardChainDataQuery_useLazy = include$1.useLazy;

var WildcardChainDataQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var WildcardChainDataQuery = {
  WildcardChainDataQuery_inner: WildcardChainDataQuery_inner,
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: WildcardChainDataQuery_refetchQueryDescription,
  use: use$1,
  useLazy: WildcardChainDataQuery_useLazy,
  useLazyWithVariables: WildcardChainDataQuery_useLazyWithVariables
};

var Raw$4 = {};

var query$4 = ((frag_0) => require("@apollo/client").gql`
  query ($tokenId: String!)  {
    launchedWildcards_by_pk(id: $tokenId)  {
      __typename
      wildcard  {
        ...WildcardMainData
      }
    }
  }
  ${frag_0}
`)(query$1);

function parse$4(value) {
  var value$1 = value.launchedWildcards_by_pk;
  return {
          launchedWildcards_by_pk: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                wildcard: parse$1(value$1.wildcard)
              }) : undefined
        };
}

function serialize$4(value) {
  var value$1 = value.launchedWildcards_by_pk;
  var launchedWildcards_by_pk;
  if (value$1 !== undefined) {
    var value$2 = value$1.wildcard;
    var wildcard = serialize$1(value$2);
    var value$3 = value$1.__typename;
    launchedWildcards_by_pk = {
      __typename: value$3,
      wildcard: wildcard
    };
  } else {
    launchedWildcards_by_pk = null;
  }
  return {
          launchedWildcards_by_pk: launchedWildcards_by_pk
        };
}

function serializeVariables$2(inp) {
  return {
          tokenId: inp.tokenId
        };
}

function makeVariables$2(tokenId, param) {
  return {
          tokenId: tokenId
        };
}

var WildcardDataQuery_inner = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$4,
      Raw: Raw$4,
      parse: parse$4,
      serialize: serialize$4,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var WildcardDataQuery_refetchQueryDescription = include$2.refetchQueryDescription;

var WildcardDataQuery_useLazy = include$2.useLazy;

var WildcardDataQuery_useLazyWithVariables = include$2.useLazyWithVariables;

var WildcardDataQuery = {
  WildcardDataQuery_inner: WildcardDataQuery_inner,
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  refetchQueryDescription: WildcardDataQuery_refetchQueryDescription,
  use: use$2,
  useLazy: WildcardDataQuery_useLazy,
  useLazyWithVariables: WildcardDataQuery_useLazyWithVariables
};

var Raw$5 = {};

var query$5 = (require("@apollo/client").gql`
  query ($address: String!, $network: String!)  {
    maticState(address: $address, network: $network)  {
      __typename
      balance
      daiNonce
      error
      stewardNonce
    }
  }
`);

function parse$5(value) {
  var value$1 = value.maticState;
  var value$2 = value$1.error;
  return {
          maticState: {
            __typename: value$1.__typename,
            balance: value$1.balance,
            daiNonce: value$1.daiNonce,
            error: !(value$2 == null) ? value$2 : undefined,
            stewardNonce: value$1.stewardNonce
          }
        };
}

function serialize$5(value) {
  var value$1 = value.maticState;
  var value$2 = value$1.stewardNonce;
  var value$3 = value$1.error;
  var error = value$3 !== undefined ? value$3 : null;
  var value$4 = value$1.daiNonce;
  var value$5 = value$1.balance;
  var value$6 = value$1.__typename;
  var maticState = {
    __typename: value$6,
    balance: value$5,
    daiNonce: value$4,
    error: error,
    stewardNonce: value$2
  };
  return {
          maticState: maticState
        };
}

function serializeVariables$3(inp) {
  return {
          address: inp.address,
          network: inp.network
        };
}

function makeVariables$3(address, network, param) {
  return {
          address: address,
          network: network
        };
}

var MaticStateQuery_inner = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$5,
      Raw: Raw$5,
      parse: parse$5,
      serialize: serialize$5,
      serializeVariables: serializeVariables$3
    });

var use$3 = include$3.use;

var MaticStateQuery_refetchQueryDescription = include$3.refetchQueryDescription;

var MaticStateQuery_useLazy = include$3.useLazy;

var MaticStateQuery_useLazyWithVariables = include$3.useLazyWithVariables;

var MaticStateQuery = {
  MaticStateQuery_inner: MaticStateQuery_inner,
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  refetchQueryDescription: MaticStateQuery_refetchQueryDescription,
  use: use$3,
  useLazy: MaticStateQuery_useLazy,
  useLazyWithVariables: MaticStateQuery_useLazyWithVariables
};

var Raw$6 = {};

var query$6 = ((frag_0) => require("@apollo/client").gql`
  query   {
    homeAnimals  {
      __typename
      id
      next
      prev
      wildcardData  {
        ...WildcardMainData
      }
    }
  }
  ${frag_0}
`)(query$1);

function parse$6(value) {
  var value$1 = value.homeAnimals;
  return {
          homeAnimals: Js_array.map((function (value) {
                  var value$1 = value.wildcardData;
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          next: value.next,
                          prev: value.prev,
                          wildcardData: !(value$1 == null) ? parse$1(value$1) : undefined
                        };
                }), value$1)
        };
}

function serialize$6(value) {
  var value$1 = value.homeAnimals;
  var homeAnimals = Js_array.map((function (value) {
          var value$1 = value.wildcardData;
          var wildcardData = value$1 !== undefined ? serialize$1(value$1) : null;
          var value$2 = value.prev;
          var value$3 = value.next;
          var value$4 = value.id;
          var value$5 = value.__typename;
          return {
                  __typename: value$5,
                  id: value$4,
                  next: value$3,
                  prev: value$2,
                  wildcardData: wildcardData
                };
        }), value$1);
  return {
          homeAnimals: homeAnimals
        };
}

function serializeVariables$4(param) {
  
}

function makeVariables$4(param) {
  
}

function makeDefaultVariables(param) {
  
}

var HomeAnimalsQuery_inner = {
  Raw: Raw$6,
  query: query$6,
  parse: parse$6,
  serialize: serialize$6,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4,
  makeDefaultVariables: makeDefaultVariables
};

var include$4 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$6,
      Raw: Raw$6,
      parse: parse$6,
      serialize: serialize$6,
      serializeVariables: serializeVariables$4
    });

var use$4 = include$4.use;

var HomeAnimalsQuery_refetchQueryDescription = include$4.refetchQueryDescription;

var HomeAnimalsQuery_useLazy = include$4.useLazy;

var HomeAnimalsQuery_useLazyWithVariables = include$4.useLazyWithVariables;

var HomeAnimalsQuery = {
  HomeAnimalsQuery_inner: HomeAnimalsQuery_inner,
  Raw: Raw$6,
  query: query$6,
  parse: parse$6,
  serialize: serialize$6,
  serializeVariables: serializeVariables$4,
  makeVariables: makeVariables$4,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: HomeAnimalsQuery_refetchQueryDescription,
  use: use$4,
  useLazy: HomeAnimalsQuery_useLazy,
  useLazyWithVariables: HomeAnimalsQuery_useLazyWithVariables
};

var Raw$7 = {};

var query$7 = ((frag_0) => require("@apollo/client").gql`
  query ($artistIdentifier: String!)  {
    artist_by_pk(id: $artistIdentifier)  {
      __typename
      eth_address
      id
      name
      website
      launchedWildcards: wildcardData(where: {id: {_is_null: false}})  {
        ...WildcardMainData
      }
      unlaunchedWildcards: wildcardData(where: {id: {_is_null: true}})  {
        ...WildcardMainData
      }
    }
  }
  ${frag_0}
`)(query$1);

function parse$7(value) {
  var value$1 = value.artist_by_pk;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.eth_address;
    var value$3 = value$1.website;
    var value$4 = value$1.launchedWildcards;
    var value$5 = value$1.unlaunchedWildcards;
    tmp = {
      __typename: value$1.__typename,
      eth_address: !(value$2 == null) ? value$2 : undefined,
      id: value$1.id,
      name: value$1.name,
      website: !(value$3 == null) ? value$3 : undefined,
      launchedWildcards: Js_array.map(parse$1, value$4),
      unlaunchedWildcards: Js_array.map(parse$1, value$5)
    };
  }
  return {
          artist_by_pk: tmp
        };
}

function serialize$7(value) {
  var value$1 = value.artist_by_pk;
  var artist_by_pk;
  if (value$1 !== undefined) {
    var value$2 = value$1.unlaunchedWildcards;
    var unlaunchedWildcards = Js_array.map(serialize$1, value$2);
    var value$3 = value$1.launchedWildcards;
    var launchedWildcards = Js_array.map(serialize$1, value$3);
    var value$4 = value$1.website;
    var website = value$4 !== undefined ? value$4 : null;
    var value$5 = value$1.name;
    var value$6 = value$1.id;
    var value$7 = value$1.eth_address;
    var eth_address = value$7 !== undefined ? value$7 : null;
    var value$8 = value$1.__typename;
    artist_by_pk = {
      __typename: value$8,
      eth_address: eth_address,
      id: value$6,
      name: value$5,
      website: website,
      launchedWildcards: launchedWildcards,
      unlaunchedWildcards: unlaunchedWildcards
    };
  } else {
    artist_by_pk = null;
  }
  return {
          artist_by_pk: artist_by_pk
        };
}

function serializeVariables$5(inp) {
  return {
          artistIdentifier: inp.artistIdentifier
        };
}

function makeVariables$5(artistIdentifier, param) {
  return {
          artistIdentifier: artistIdentifier
        };
}

var ArtistQuery_inner = {
  Raw: Raw$7,
  query: query$7,
  parse: parse$7,
  serialize: serialize$7,
  serializeVariables: serializeVariables$5,
  makeVariables: makeVariables$5
};

var include$5 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$7,
      Raw: Raw$7,
      parse: parse$7,
      serialize: serialize$7,
      serializeVariables: serializeVariables$5
    });

var use$5 = include$5.use;

var ArtistQuery_refetchQueryDescription = include$5.refetchQueryDescription;

var ArtistQuery_useLazy = include$5.useLazy;

var ArtistQuery_useLazyWithVariables = include$5.useLazyWithVariables;

var ArtistQuery = {
  ArtistQuery_inner: ArtistQuery_inner,
  Raw: Raw$7,
  query: query$7,
  parse: parse$7,
  serialize: serialize$7,
  serializeVariables: serializeVariables$5,
  makeVariables: makeVariables$5,
  refetchQueryDescription: ArtistQuery_refetchQueryDescription,
  use: use$5,
  useLazy: ArtistQuery_useLazy,
  useLazyWithVariables: ArtistQuery_useLazyWithVariables
};

var Raw$8 = {};

var query$8 = (require("@apollo/client").gql`
  query ($patronId: String!)  {
    patron(id: $patronId)  {
      __typename
      id
      previouslyOwnedTokens  {
        __typename
        tokenId
      }
      tokens  {
        __typename
        tokenId
      }
      availableDeposit
      patronTokenCostScaledNumerator
      foreclosureTime
      address
      lastUpdated
      totalLoyaltyTokens
      totalLoyaltyTokensIncludingUnRedeemed
      totalContributed
    }
  }
`);

function parse$8(value) {
  var value$1 = value.patron;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.previouslyOwnedTokens;
    var value$3 = value$1.tokens;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      previouslyOwnedTokens: Js_array.map((function (value) {
              return {
                      __typename: value.__typename,
                      tokenId: value.tokenId
                    };
            }), value$2),
      tokens: Js_array.map((function (value) {
              return {
                      __typename: value.__typename,
                      tokenId: value.tokenId
                    };
            }), value$3),
      availableDeposit: GqlConverters.Price.parse(value$1.availableDeposit),
      patronTokenCostScaledNumerator: GqlConverters.$$BigInt.parse(value$1.patronTokenCostScaledNumerator),
      foreclosureTime: GqlConverters.$$BigInt.parse(value$1.foreclosureTime),
      address: GqlConverters.GqlAddress.parse(value$1.address),
      lastUpdated: GqlConverters.$$BigInt.parse(value$1.lastUpdated),
      totalLoyaltyTokens: GqlConverters.$$BigInt.parse(value$1.totalLoyaltyTokens),
      totalLoyaltyTokensIncludingUnRedeemed: GqlConverters.$$BigInt.parse(value$1.totalLoyaltyTokensIncludingUnRedeemed),
      totalContributed: GqlConverters.$$BigInt.parse(value$1.totalContributed)
    };
  }
  return {
          patron: tmp
        };
}

function serialize$8(value) {
  var value$1 = value.patron;
  var patron;
  if (value$1 !== undefined) {
    var value$2 = value$1.totalContributed;
    var value$3 = GqlConverters.$$BigInt.serialize(value$2);
    var value$4 = value$1.totalLoyaltyTokensIncludingUnRedeemed;
    var value$5 = GqlConverters.$$BigInt.serialize(value$4);
    var value$6 = value$1.totalLoyaltyTokens;
    var value$7 = GqlConverters.$$BigInt.serialize(value$6);
    var value$8 = value$1.lastUpdated;
    var value$9 = GqlConverters.$$BigInt.serialize(value$8);
    var value$10 = value$1.address;
    var value$11 = GqlConverters.GqlAddress.serialize(value$10);
    var value$12 = value$1.foreclosureTime;
    var value$13 = GqlConverters.$$BigInt.serialize(value$12);
    var value$14 = value$1.patronTokenCostScaledNumerator;
    var value$15 = GqlConverters.$$BigInt.serialize(value$14);
    var value$16 = value$1.availableDeposit;
    var value$17 = GqlConverters.Price.serialize(value$16);
    var value$18 = value$1.tokens;
    var tokens = Js_array.map((function (value) {
            var value$1 = value.tokenId;
            var value$2 = value.__typename;
            return {
                    __typename: value$2,
                    tokenId: value$1
                  };
          }), value$18);
    var value$19 = value$1.previouslyOwnedTokens;
    var previouslyOwnedTokens = Js_array.map((function (value) {
            var value$1 = value.tokenId;
            var value$2 = value.__typename;
            return {
                    __typename: value$2,
                    tokenId: value$1
                  };
          }), value$19);
    var value$20 = value$1.id;
    var value$21 = value$1.__typename;
    patron = {
      __typename: value$21,
      id: value$20,
      previouslyOwnedTokens: previouslyOwnedTokens,
      tokens: tokens,
      availableDeposit: value$17,
      patronTokenCostScaledNumerator: value$15,
      foreclosureTime: value$13,
      address: value$11,
      lastUpdated: value$9,
      totalLoyaltyTokens: value$7,
      totalLoyaltyTokensIncludingUnRedeemed: value$5,
      totalContributed: value$3
    };
  } else {
    patron = null;
  }
  return {
          patron: patron
        };
}

function serializeVariables$6(inp) {
  return {
          patronId: inp.patronId
        };
}

function makeVariables$6(patronId, param) {
  return {
          patronId: patronId
        };
}

var LoadPatron_inner = {
  Raw: Raw$8,
  query: query$8,
  parse: parse$8,
  serialize: serialize$8,
  serializeVariables: serializeVariables$6,
  makeVariables: makeVariables$6
};

var include$6 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$8,
      Raw: Raw$8,
      parse: parse$8,
      serialize: serialize$8,
      serializeVariables: serializeVariables$6
    });

var use$6 = include$6.use;

var LoadPatron_refetchQueryDescription = include$6.refetchQueryDescription;

var LoadPatron_useLazy = include$6.useLazy;

var LoadPatron_useLazyWithVariables = include$6.useLazyWithVariables;

var LoadPatron = {
  LoadPatron_inner: LoadPatron_inner,
  Raw: Raw$8,
  query: query$8,
  parse: parse$8,
  serialize: serialize$8,
  serializeVariables: serializeVariables$6,
  makeVariables: makeVariables$6,
  refetchQueryDescription: LoadPatron_refetchQueryDescription,
  use: use$6,
  useLazy: LoadPatron_useLazy,
  useLazyWithVariables: LoadPatron_useLazyWithVariables
};

var Raw$9 = {};

var query$9 = (require("@apollo/client").gql`
  query ($wildcardIdArray: [String!]!)  {
    wildcards(where: {id_in: $wildcardIdArray})  {
      __typename
      id
      totalCollected
      patronageNumeratorPriceScaled
      timeCollected
    }
  }
`);

function parse$9(value) {
  var value$1 = value.wildcards;
  return {
          wildcards: Js_array.map((function (value) {
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          totalCollected: GqlConverters.Price.parse(value.totalCollected),
                          patronageNumeratorPriceScaled: GqlConverters.$$BigInt.parse(value.patronageNumeratorPriceScaled),
                          timeCollected: GqlConverters.$$BigInt.parse(value.timeCollected)
                        };
                }), value$1)
        };
}

function serialize$9(value) {
  var value$1 = value.wildcards;
  var wildcards = Js_array.map((function (value) {
          var value$1 = value.timeCollected;
          var value$2 = GqlConverters.$$BigInt.serialize(value$1);
          var value$3 = value.patronageNumeratorPriceScaled;
          var value$4 = GqlConverters.$$BigInt.serialize(value$3);
          var value$5 = value.totalCollected;
          var value$6 = GqlConverters.Price.serialize(value$5);
          var value$7 = value.id;
          var value$8 = value.__typename;
          return {
                  __typename: value$8,
                  id: value$7,
                  totalCollected: value$6,
                  patronageNumeratorPriceScaled: value$4,
                  timeCollected: value$2
                };
        }), value$1);
  return {
          wildcards: wildcards
        };
}

function serializeVariables$7(inp) {
  var a = inp.wildcardIdArray;
  return {
          wildcardIdArray: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function makeVariables$7(wildcardIdArray, param) {
  return {
          wildcardIdArray: wildcardIdArray
        };
}

var LoadTokenDataArray_inner = {
  Raw: Raw$9,
  query: query$9,
  parse: parse$9,
  serialize: serialize$9,
  serializeVariables: serializeVariables$7,
  makeVariables: makeVariables$7
};

var include$7 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$9,
      Raw: Raw$9,
      parse: parse$9,
      serialize: serialize$9,
      serializeVariables: serializeVariables$7
    });

var use$7 = include$7.use;

var LoadTokenDataArray_refetchQueryDescription = include$7.refetchQueryDescription;

var LoadTokenDataArray_useLazy = include$7.useLazy;

var LoadTokenDataArray_useLazyWithVariables = include$7.useLazyWithVariables;

var LoadTokenDataArray = {
  LoadTokenDataArray_inner: LoadTokenDataArray_inner,
  Raw: Raw$9,
  query: query$9,
  parse: parse$9,
  serialize: serialize$9,
  serializeVariables: serializeVariables$7,
  makeVariables: makeVariables$7,
  refetchQueryDescription: LoadTokenDataArray_refetchQueryDescription,
  use: use$7,
  useLazy: LoadTokenDataArray_useLazy,
  useLazyWithVariables: LoadTokenDataArray_useLazyWithVariables
};

var Raw$10 = {};

var query$10 = (require("@apollo/client").gql`
  query ($orgId: String!)  {
    organisations_by_pk(id: $orgId)  {
      __typename
      description
      name
      website
      wildcard(where: {id: {_is_null: false}})  {
        __typename
        id
      }
      unlaunched: wildcard(where: {id: {_is_null: true}, real_wc_photos: {image: {_is_null: false}}})  {
        __typename
        key
        real_wc_photos  {
          __typename
          image
          photographer
        }
        name
        commonName
        description
      }
      logo
      logo_badge
      youtube_vid
    }
  }
`);

function parse$10(value) {
  var value$1 = value.organisations_by_pk;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.wildcard;
    var value$3 = value$1.unlaunched;
    var value$4 = value$1.logo_badge;
    var value$5 = value$1.youtube_vid;
    tmp = {
      __typename: value$1.__typename,
      description: value$1.description,
      name: value$1.name,
      website: value$1.website,
      wildcard: Js_array.map((function (value) {
              var value$1 = value.id;
              return {
                      __typename: value.__typename,
                      id: !(value$1 == null) ? Caml_option.some(GqlConverters.GqlTokenIdStr.parse(value$1)) : undefined
                    };
            }), value$2),
      unlaunched: Js_array.map((function (value) {
              var value$1 = value.real_wc_photos;
              var value$2 = value.name;
              var value$3 = value.commonName;
              return {
                      __typename: value.__typename,
                      key: value.key,
                      real_wc_photos: Js_array.map((function (value) {
                              var value$1 = value.photographer;
                              return {
                                      __typename: value.__typename,
                                      image: value.image,
                                      photographer: !(value$1 == null) ? value$1 : undefined
                                    };
                            }), value$1),
                      name: !(value$2 == null) ? value$2 : undefined,
                      commonName: !(value$3 == null) ? value$3 : undefined,
                      description: value.description
                    };
            }), value$3),
      logo: value$1.logo,
      logo_badge: !(value$4 == null) ? value$4 : undefined,
      youtube_vid: !(value$5 == null) ? value$5 : undefined
    };
  }
  return {
          organisations_by_pk: tmp
        };
}

function serialize$10(value) {
  var value$1 = value.organisations_by_pk;
  var organisations_by_pk;
  if (value$1 !== undefined) {
    var value$2 = value$1.youtube_vid;
    var youtube_vid = value$2 !== undefined ? value$2 : null;
    var value$3 = value$1.logo_badge;
    var logo_badge = value$3 !== undefined ? value$3 : null;
    var value$4 = value$1.logo;
    var value$5 = value$1.unlaunched;
    var unlaunched = Js_array.map((function (value) {
            var value$1 = value.description;
            var value$2 = value.commonName;
            var commonName = value$2 !== undefined ? value$2 : null;
            var value$3 = value.name;
            var name = value$3 !== undefined ? value$3 : null;
            var value$4 = value.real_wc_photos;
            var real_wc_photos = Js_array.map((function (value) {
                    var value$1 = value.photographer;
                    var photographer = value$1 !== undefined ? value$1 : null;
                    var value$2 = value.image;
                    var value$3 = value.__typename;
                    return {
                            __typename: value$3,
                            image: value$2,
                            photographer: photographer
                          };
                  }), value$4);
            var value$5 = value.key;
            var value$6 = value.__typename;
            return {
                    __typename: value$6,
                    key: value$5,
                    real_wc_photos: real_wc_photos,
                    name: name,
                    commonName: commonName,
                    description: value$1
                  };
          }), value$5);
    var value$6 = value$1.wildcard;
    var wildcard = Js_array.map((function (value) {
            var value$1 = value.id;
            var id = value$1 !== undefined ? GqlConverters.GqlTokenIdStr.serialize(Caml_option.valFromOption(value$1)) : null;
            var value$2 = value.__typename;
            return {
                    __typename: value$2,
                    id: id
                  };
          }), value$6);
    var value$7 = value$1.website;
    var value$8 = value$1.name;
    var value$9 = value$1.description;
    var value$10 = value$1.__typename;
    organisations_by_pk = {
      __typename: value$10,
      description: value$9,
      name: value$8,
      website: value$7,
      wildcard: wildcard,
      unlaunched: unlaunched,
      logo: value$4,
      logo_badge: logo_badge,
      youtube_vid: youtube_vid
    };
  } else {
    organisations_by_pk = null;
  }
  return {
          organisations_by_pk: organisations_by_pk
        };
}

function serializeVariables$8(inp) {
  return {
          orgId: inp.orgId
        };
}

function makeVariables$8(orgId, param) {
  return {
          orgId: orgId
        };
}

var LoadOrganisationData_inner = {
  Raw: Raw$10,
  query: query$10,
  parse: parse$10,
  serialize: serialize$10,
  serializeVariables: serializeVariables$8,
  makeVariables: makeVariables$8
};

var include$8 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$10,
      Raw: Raw$10,
      parse: parse$10,
      serialize: serialize$10,
      serializeVariables: serializeVariables$8
    });

var use$8 = include$8.use;

var LoadOrganisationData_refetchQueryDescription = include$8.refetchQueryDescription;

var LoadOrganisationData_useLazy = include$8.useLazy;

var LoadOrganisationData_useLazyWithVariables = include$8.useLazyWithVariables;

var LoadOrganisationData = {
  LoadOrganisationData_inner: LoadOrganisationData_inner,
  Raw: Raw$10,
  query: query$10,
  parse: parse$10,
  serialize: serialize$10,
  serializeVariables: serializeVariables$8,
  makeVariables: makeVariables$8,
  refetchQueryDescription: LoadOrganisationData_refetchQueryDescription,
  use: use$8,
  useLazy: LoadOrganisationData_useLazy,
  useLazyWithVariables: LoadOrganisationData_useLazyWithVariables
};

var Raw$11 = {};

var query$11 = (require("@apollo/client").gql`
  query ($numberOfLeaders: Int!)  {
    patrons(first: $numberOfLeaders, orderBy: patronTokenCostScaledNumerator, orderDirection: desc, where: {id_not: "NO_OWNER"})  {
      __typename
      id
      patronTokenCostScaledNumerator
    }
  }
`);

function parse$11(value) {
  var value$1 = value.patrons;
  return {
          patrons: Js_array.map((function (value) {
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          patronTokenCostScaledNumerator: GqlConverters.$$BigInt.parse(value.patronTokenCostScaledNumerator)
                        };
                }), value$1)
        };
}

function serialize$11(value) {
  var value$1 = value.patrons;
  var patrons = Js_array.map((function (value) {
          var value$1 = value.patronTokenCostScaledNumerator;
          var value$2 = GqlConverters.$$BigInt.serialize(value$1);
          var value$3 = value.id;
          var value$4 = value.__typename;
          return {
                  __typename: value$4,
                  id: value$3,
                  patronTokenCostScaledNumerator: value$2
                };
        }), value$1);
  return {
          patrons: patrons
        };
}

function serializeVariables$9(inp) {
  return {
          numberOfLeaders: inp.numberOfLeaders
        };
}

function makeVariables$9(numberOfLeaders, param) {
  return {
          numberOfLeaders: numberOfLeaders
        };
}

var LoadTopContributors_inner = {
  Raw: Raw$11,
  query: query$11,
  parse: parse$11,
  serialize: serialize$11,
  serializeVariables: serializeVariables$9,
  makeVariables: makeVariables$9
};

var include$9 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$11,
      Raw: Raw$11,
      parse: parse$11,
      serialize: serialize$11,
      serializeVariables: serializeVariables$9
    });

var use$9 = include$9.use;

var LoadTopContributors_refetchQueryDescription = include$9.refetchQueryDescription;

var LoadTopContributors_useLazy = include$9.useLazy;

var LoadTopContributors_useLazyWithVariables = include$9.useLazyWithVariables;

var LoadTopContributors = {
  LoadTopContributors_inner: LoadTopContributors_inner,
  Raw: Raw$11,
  query: query$11,
  parse: parse$11,
  serialize: serialize$11,
  serializeVariables: serializeVariables$9,
  makeVariables: makeVariables$9,
  refetchQueryDescription: LoadTopContributors_refetchQueryDescription,
  use: use$9,
  useLazy: LoadTopContributors_useLazy,
  useLazyWithVariables: LoadTopContributors_useLazyWithVariables
};

var Raw$12 = {};

var query$12 = (require("@apollo/client").gql`
  query ($id: String!)  {
    global(id: $id)  {
      __typename
      id
      totalCollectedOrDueAccurate
      timeLastCollected
      totalTokenCostScaledNumeratorAccurate
    }
  }
`);

function parse$12(value) {
  var value$1 = value.global;
  return {
          global: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                id: value$1.id,
                totalCollectedOrDueAccurate: GqlConverters.$$BigInt.parse(value$1.totalCollectedOrDueAccurate),
                timeLastCollected: GqlConverters.$$BigInt.parse(value$1.timeLastCollected),
                totalTokenCostScaledNumeratorAccurate: GqlConverters.$$BigInt.parse(value$1.totalTokenCostScaledNumeratorAccurate)
              }) : undefined
        };
}

function serialize$12(value) {
  var value$1 = value.global;
  var $$global;
  if (value$1 !== undefined) {
    var value$2 = value$1.totalTokenCostScaledNumeratorAccurate;
    var value$3 = GqlConverters.$$BigInt.serialize(value$2);
    var value$4 = value$1.timeLastCollected;
    var value$5 = GqlConverters.$$BigInt.serialize(value$4);
    var value$6 = value$1.totalCollectedOrDueAccurate;
    var value$7 = GqlConverters.$$BigInt.serialize(value$6);
    var value$8 = value$1.id;
    var value$9 = value$1.__typename;
    $$global = {
      __typename: value$9,
      id: value$8,
      totalCollectedOrDueAccurate: value$7,
      timeLastCollected: value$5,
      totalTokenCostScaledNumeratorAccurate: value$3
    };
  } else {
    $$global = null;
  }
  return {
          global: $$global
        };
}

function serializeVariables$10(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$10(id, param) {
  return {
          id: id
        };
}

var SubTotalRaisedOrDueQuery_inner = {
  Raw: Raw$12,
  query: query$12,
  parse: parse$12,
  serialize: serialize$12,
  serializeVariables: serializeVariables$10,
  makeVariables: makeVariables$10
};

var include$10 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$12,
      Raw: Raw$12,
      parse: parse$12,
      serialize: serialize$12,
      serializeVariables: serializeVariables$10
    });

var use$10 = include$10.use;

var SubTotalRaisedOrDueQuery_refetchQueryDescription = include$10.refetchQueryDescription;

var SubTotalRaisedOrDueQuery_useLazy = include$10.useLazy;

var SubTotalRaisedOrDueQuery_useLazyWithVariables = include$10.useLazyWithVariables;

var SubTotalRaisedOrDueQuery = {
  SubTotalRaisedOrDueQuery_inner: SubTotalRaisedOrDueQuery_inner,
  Raw: Raw$12,
  query: query$12,
  parse: parse$12,
  serialize: serialize$12,
  serializeVariables: serializeVariables$10,
  makeVariables: makeVariables$10,
  refetchQueryDescription: SubTotalRaisedOrDueQuery_refetchQueryDescription,
  use: use$10,
  useLazy: SubTotalRaisedOrDueQuery_useLazy,
  useLazyWithVariables: SubTotalRaisedOrDueQuery_useLazyWithVariables
};

function getQueryPrefix(chain) {
  if (chain !== 1) {
    return "";
  } else {
    return "matic";
  }
}

function useWildcardQuery(chain, forceRefetchOpt, tokenId) {
  var forceRefetch = forceRefetchOpt !== undefined ? forceRefetchOpt : false;
  var wildcardQuery = Curry.app(use$1, [
        undefined,
        {
          context: chain
        },
        undefined,
        undefined,
        forceRefetch ? /* CacheAndNetwork */0 : /* CacheFirst */1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          tokenId: getQueryPrefix(chain) + TokenId.toString(tokenId)
        }
      ]);
  if (wildcardQuery.loading || wildcardQuery.error !== undefined) {
    return ;
  } else {
    return wildcardQuery.data;
  }
}

function useLoadTokenDataArrayQuery(chain, tokenIdArray) {
  var tokenDataQuery = Curry.app(use$7, [
        undefined,
        {
          context: chain
        },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          wildcardIdArray: Belt_Array.map(tokenIdArray, TokenId.toString)
        }
      ]);
  if (tokenDataQuery.loading || tokenDataQuery.error !== undefined) {
    return ;
  } else {
    return tokenDataQuery.data;
  }
}

function useWildcardDataQuery(tokenId) {
  var wildcardQuery = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          tokenId: TokenId.toString(tokenId)
        }
      ]);
  var match = wildcardQuery.data;
  if (wildcardQuery.loading || wildcardQuery.error !== undefined || match === undefined) {
    return ;
  } else {
    return match.launchedWildcards_by_pk;
  }
}

function useHomeAnimalsQuery(param) {
  var match = Curry.app(use$4, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match$1 = match.data;
  if (match.loading || match.error !== undefined || match$1 === undefined) {
    return ;
  } else {
    return match$1.homeAnimals;
  }
}

function useLoadOrganisationQuery(orgId) {
  var orgQuery = Curry.app(use$8, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          orgId: orgId
        }
      ]);
  var match = orgQuery.data;
  if (orgQuery.loading || orgQuery.error !== undefined || match === undefined) {
    return ;
  } else {
    return match.organisations_by_pk;
  }
}

function useLoadOrganisationLogo(orgId) {
  var match = useLoadOrganisationQuery(orgId);
  if (match !== undefined) {
    return match.logo;
  }
  
}

function useLoadOrganisationLogoBadge(orgId) {
  var match = useLoadOrganisationQuery(orgId);
  if (match === undefined) {
    return ;
  }
  var badge = match.logo_badge;
  if (badge !== undefined) {
    return badge;
  } else {
    return match.logo;
  }
}

function useHomePageAnimalArray(param) {
  var homeAnimals = useHomeAnimalsQuery(undefined);
  if (homeAnimals !== undefined) {
    return Belt_Array.map(homeAnimals, (function (animal) {
                  return {
                          id: TokenId.fromStringUnsafe(animal.id),
                          prev: TokenId.fromStringUnsafe(animal.prev),
                          next: TokenId.fromStringUnsafe(animal.next)
                        };
                }));
  } else {
    return [];
  }
}

function useDetailsPageNextPrevious(currentToken) {
  var homepageAnimalData = useHomePageAnimalArray(undefined);
  var defaultValue_id = TokenId.fromStringUnsafe("2");
  var defaultValue_prev = TokenId.fromStringUnsafe("0");
  var defaultValue_next = TokenId.fromStringUnsafe("1");
  var defaultValue = {
    id: defaultValue_id,
    prev: defaultValue_prev,
    next: defaultValue_next
  };
  var forwardNextLookup = React.useMemo((function () {
          return Belt_Array.reduce(homepageAnimalData, {}, (function (dict, item) {
                        dict[TokenId.toString(item.id)] = item;
                        return dict;
                      }));
        }), [homepageAnimalData]);
  return Belt_Option.getWithDefault(Js_dict.get(forwardNextLookup, TokenId.toString(currentToken)), defaultValue);
}

function animalDescription_decode(v) {
  return Spice.arrayFromJson(Spice.stringFromJson, v);
}

function useWildcardDescription(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match !== undefined) {
    return Belt_Result.mapWithDefault(Spice.arrayFromJson(Spice.stringFromJson, match.wildcard.description), undefined, (function (descriptionArray) {
                  return descriptionArray;
                }));
  }
  
}

function useWildcardName(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match !== undefined) {
    return match.wildcard.name;
  }
  
}

function useWildcardCommonName(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match !== undefined) {
    return match.wildcard.commonName;
  }
  
}

function useWildcardAvatar(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match !== undefined) {
    return match.wildcard.image;
  }
  
}

function useWildcardArtist(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match !== undefined) {
    return match.wildcard.artistOfWildcard;
  }
  
}

function useRealImages(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match !== undefined) {
    return match.wildcard.real_wc_photos;
  }
  
}

function useWildcardOrgId(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard.organization;
  if (match$1 !== undefined) {
    return match$1.id;
  }
  
}

function useWildcardOrgName(tokenId) {
  var match = useWildcardDataQuery(tokenId);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard.organization;
  if (match$1 !== undefined) {
    return match$1.name;
  }
  
}

function useLoadTopContributors(numberOfLeaders) {
  var topContributorsQuery = Curry.app(use$9, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          numberOfLeaders: numberOfLeaders
        }
      ]);
  var match = topContributorsQuery.data;
  if (topContributorsQuery.loading || topContributorsQuery.error !== undefined || match === undefined) {
    return ;
  } else {
    return match.patrons;
  }
}

function useLoadTopContributorsData(numberOfLeaders) {
  var topContributors = useLoadTopContributors(numberOfLeaders);
  if (topContributors !== undefined) {
    return Belt_Array.map(topContributors, (function (patron) {
                  var monthlyContribution = Web3Utils.fromWeiBNToEthPrecision(patron.patronTokenCostScaledNumerator.mul(CONSTANTS.secondsInAMonthBn).div(CONSTANTS.secondsIn365DaysPrecisionScaled), 4);
                  return [
                          patron.id,
                          monthlyContribution
                        ];
                }));
  }
  
}

function usePatron(chain, animal) {
  var match = useWildcardQuery(chain, undefined, animal);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard;
  if (match$1 !== undefined) {
    return match$1.owner.address;
  }
  
}

function useIsAnimalOwned(chain, ownedAnimal) {
  var currentAccount = Belt_Option.mapWithDefault(RootProvider.useCurrentUser(undefined), "loading", (function (a) {
          return a;
        }));
  var currentPatron = Belt_Option.mapWithDefault(usePatron(chain, ownedAnimal), "no-patron-defined", (function (a) {
          return a;
        }));
  return currentAccount.toLowerCase() === currentPatron.toLocaleLowerCase();
}

function useTimeAcquired(chain, animal) {
  var match = useWildcardQuery(chain, undefined, animal);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard;
  if (match$1 !== undefined) {
    return Caml_option.some(match$1.timeAcquired);
  }
  
}

function useQueryPatronQuery(chain, forceRefetchOpt, patron) {
  var forceRefetch = forceRefetchOpt !== undefined ? forceRefetchOpt : false;
  return Curry.app(use$6, [
              undefined,
              {
                context: chain
              },
              undefined,
              undefined,
              forceRefetch ? /* CacheAndNetwork */0 : /* CacheFirst */1,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              {
                patronId: getQueryPrefix(chain) + patron
              }
            ]);
}

function useQueryPatron(chain, patron) {
  var loadPatronQuery = useQueryPatronQuery(chain, undefined, patron);
  var match = loadPatronQuery.data;
  if (match !== undefined) {
    return match.patron;
  }
  
}

function useForeclosureTimeBn(chain, patron) {
  var match = useQueryPatron(chain, patron);
  if (match !== undefined) {
    return Caml_option.some(match.foreclosureTime);
  }
  
}

function useForeclosureTime(chain, patron) {
  return Belt_Option.map(useForeclosureTimeBn(chain, patron), Helper.bnToMoment);
}

function useTimeAcquiredWithDefault(chain, animal, $$default) {
  return Belt_Option.getWithDefault(useTimeAcquired(chain, animal), $$default);
}

function useDaysHeld(chain, tokenId) {
  return Belt_Option.map(useTimeAcquired(chain, tokenId), (function (moment) {
                return [
                        Moment().diff(moment, "days"),
                        moment
                      ];
              }));
}

function useTotalCollectedOrDue(chain) {
  var subTotalRaisedQuery = Curry.app(use$10, [
        undefined,
        {
          context: chain
        },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          id: chain !== 1 ? "1" : "Matic-Global"
        }
      ]);
  var match = subTotalRaisedQuery.data;
  if (subTotalRaisedQuery.error !== undefined || match === undefined) {
    return ;
  } else {
    return match.global;
  }
}

function getCurrentTimestamp(param) {
  return String(Js_math.floor(Date.now() / 1000));
}

function useCurrentTime(param) {
  var match = React.useState(function () {
        return getCurrentTimestamp(undefined);
      });
  var setTimeLeft = match[1];
  React.useEffect((function () {
          var interval = setInterval((function (param) {
                  Curry._1(setTimeLeft, (function (param) {
                          return getCurrentTimestamp(undefined);
                        }));
                }), 2000);
          return (function (param) {
                    clearInterval(interval);
                  });
        }), [setTimeLeft]);
  return match[0];
}

function useCurrentTimestampBn(param) {
  return new BnJs(useCurrentTime(undefined));
}

function useAmountRaised(chain) {
  var currentTimestamp = useCurrentTime(undefined);
  return Belt_Option.map(useTotalCollectedOrDue(chain), (function (param) {
                var timeElapsed = new BnJs(currentTimestamp).sub(param.timeLastCollected);
                var amountRaisedSinceLastCollection = param.totalTokenCostScaledNumeratorAccurate.mul(timeElapsed).div(CONSTANTS.secondsIn365DaysPrecisionScaled);
                return param.totalCollectedOrDueAccurate.add(amountRaisedSinceLastCollection);
              }));
}

function useTotalCollectedToken(chain, animal) {
  var match = useWildcardQuery(chain, undefined, animal);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard;
  if (match$1 !== undefined) {
    return [
            match$1.totalCollected,
            match$1.timeCollected,
            match$1.patronageNumeratorPriceScaled
          ];
  }
  
}

function usePatronageNumerator(chain, tokenId) {
  var match = useWildcardQuery(chain, undefined, tokenId);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard;
  if (match$1 !== undefined) {
    return Caml_option.some(match$1.patronageNumerator);
  }
  
}

function usePledgeRate(chain, tokenId) {
  var optPatronageNumerator = usePatronageNumerator(chain, tokenId);
  return React.useMemo((function () {
                if (optPatronageNumerator === undefined) {
                  return 0;
                }
                var result = Caml_option.valFromOption(optPatronageNumerator).div(new BnJs("12000000000"));
                return result.toNumber() / 1000;
              }), [optPatronageNumerator]);
}

function usePledgeRateDetailed(chain, tokenId) {
  var pledgeRate = usePledgeRate(chain, tokenId);
  var inversePledgeRate = 1 / pledgeRate;
  var numeratorOverYear = String(pledgeRate * 1200 | 0);
  return [
          numeratorOverYear,
          "100",
          pledgeRate,
          inversePledgeRate
        ];
}

function usePatronLoyaltyTokenDetails(chain, address) {
  var patron = useQueryPatron(chain, address);
  if (patron !== undefined) {
    return {
            currentLoyaltyTokens: patron.totalLoyaltyTokens,
            currentLoyaltyTokensIncludingUnredeemed: patron.totalLoyaltyTokensIncludingUnRedeemed,
            lastCollected: patron.lastUpdated,
            numberOfAnimalsOwned: new BnJs(String(patron.tokens.length))
          };
  }
  
}

function useAmountRaisedToken(chain, animal) {
  var currentTimestamp = useCurrentTime(undefined);
  var match = useTotalCollectedToken(chain, animal);
  if (match === undefined) {
    return ;
  }
  var timeElapsed = new BnJs(currentTimestamp).sub(match[1]);
  var amountRaisedSinceLastCollection = match[2].mul(timeElapsed).div(CONSTANTS.secondsIn365DaysPrecisionScaled);
  return Caml_option.some(match[0].add(amountRaisedSinceLastCollection));
}

function calculateTotalRaised(currentTimestamp, param) {
  var timeElapsed = new BnJs(currentTimestamp).sub(param[1]);
  var amountRaisedSinceLastCollection = param[2].mul(timeElapsed).div(CONSTANTS.secondsIn365DaysPrecisionScaled);
  return param[0].add(amountRaisedSinceLastCollection);
}

function useTotalRaisedAnimalGroup(animals) {
  var currentTimestamp = useCurrentTime(undefined);
  var detailsMainnet = useLoadTokenDataArrayQuery(/* MainnetQuery */2, animals);
  var detailsMatic = useLoadTokenDataArrayQuery(/* MaticQuery */1, Belt_Array.map(animals, (function (id) {
              return "matic" + id;
            })));
  return [
          detailsMainnet !== undefined ? Caml_option.some(Belt_Array.reduce(detailsMainnet.wildcards, CONSTANTS.zeroBn, (function (acc, animalDetails) {
                        return calculateTotalRaised(currentTimestamp, [
                                      animalDetails.totalCollected,
                                      animalDetails.timeCollected,
                                      animalDetails.patronageNumeratorPriceScaled
                                    ]).add(acc);
                      }))) : undefined,
          detailsMatic !== undefined ? Caml_option.some(Belt_Array.reduce(detailsMatic.wildcards, CONSTANTS.zeroBn, (function (acc, animalDetails) {
                        return calculateTotalRaised(currentTimestamp, [
                                      animalDetails.totalCollected,
                                      animalDetails.timeCollected,
                                      animalDetails.patronageNumeratorPriceScaled
                                    ]).add(acc);
                      }))) : undefined
        ];
}

function useTimeSinceTokenWasLastSettled(chain, animal) {
  var currentTimestamp = useCurrentTime(undefined);
  var match = useTotalCollectedToken(chain, animal);
  if (match !== undefined) {
    return Caml_option.some(new BnJs(currentTimestamp).sub(match[1]));
  }
  
}

function useUnredeemedLoyaltyTokenDueForUser(chain, animal, numberOfTokens) {
  var timeSinceTokenWasLastSettled = useTimeSinceTokenWasLastSettled(chain, animal);
  if (timeSinceTokenWasLastSettled === undefined) {
    return ;
  }
  var totalLoyaltyTokensPerSecondPerAnimal = new BnJs("11574074074074");
  return Caml_option.some(Caml_option.valFromOption(timeSinceTokenWasLastSettled).mul(totalLoyaltyTokensPerSecondPerAnimal).mul(new BnJs(numberOfTokens)));
}

function useTotalLoyaltyToken(chain, patron) {
  var currentTimestamp = useCurrentTime(undefined);
  var match = usePatronLoyaltyTokenDetails(chain, patron);
  if (match === undefined) {
    return ;
  }
  var timeElapsed = new BnJs(currentTimestamp).sub(match.lastCollected);
  var totalLoyaltyTokensPerSecondPerAnimal = new BnJs("11574074074074");
  var totalLoyaltyTokensFor1Animal = totalLoyaltyTokensPerSecondPerAnimal.mul(timeElapsed);
  var totalLoyaltyTokensForAllAnimals = match.numberOfAnimalsOwned.mul(totalLoyaltyTokensFor1Animal);
  var totalLoyaltyTokensForUser = match.currentLoyaltyTokensIncludingUnredeemed.add(totalLoyaltyTokensForAllAnimals);
  return [
          totalLoyaltyTokensForUser,
          match.currentLoyaltyTokens
        ];
}

function useRemainingDepositEth(chain, patron) {
  var currentTimestamp = useCurrentTime(undefined);
  var match = useQueryPatron(chain, patron);
  if (match === undefined) {
    return ;
  }
  var timeElapsed = new BnJs(currentTimestamp).sub(match.lastUpdated);
  var amountRaisedSinceLastCollection = match.patronTokenCostScaledNumerator.mul(timeElapsed).div(CONSTANTS.secondsIn365DaysPrecisionScaled);
  return Caml_option.some(match.availableDeposit.sub(amountRaisedSinceLastCollection));
}

function usePrice(chain, tokenId) {
  var wildcardData = useWildcardQuery(chain, undefined, tokenId);
  var optCurrentPatron = usePatron(chain, tokenId);
  var currentPatron = Belt_Option.mapWithDefault(optCurrentPatron, "no-patron-defined", (function (a) {
          return a;
        }));
  var currentTime = useCurrentTime(undefined);
  var foreclosureTime = useForeclosureTimeBn(chain, currentPatron);
  if (wildcardData === undefined) {
    return /* Loading */0;
  }
  var match = wildcardData.wildcard;
  if (match === undefined) {
    return /* Loading */0;
  }
  if (optCurrentPatron === undefined) {
    return /* Loading */0;
  }
  var price = match.price.price;
  if (foreclosureTime === undefined) {
    return {
            TAG: /* Price */1,
            _0: price
          };
  }
  var foreclosureTime$1 = Caml_option.valFromOption(foreclosureTime);
  if (foreclosureTime$1.lt(new BnJs(currentTime))) {
    return {
            TAG: /* Foreclosed */0,
            _0: foreclosureTime$1
          };
  } else {
    return {
            TAG: /* Price */1,
            _0: price
          };
  }
}

function useIsForeclosed(chain, currentPatron) {
  var optAvailableDeposit = useRemainingDepositEth(chain, currentPatron);
  return Belt_Option.mapWithDefault(optAvailableDeposit, true, (function (availableDeposit) {
                return !availableDeposit.gt(CONSTANTS.zeroBn);
              }));
}

function useAuctionStartPrice(chain, _tokenId) {
  var optData = useInitialDataLoad(chain);
  return Belt_Option.map(Belt_Option.flatMap(optData, (function (data) {
                    return data.global;
                  })), (function ($$global) {
                return $$global.defaultAuctionStartPrice;
              }));
}

function useAuctionEndPrice(chain, _tokenId) {
  var optData = useInitialDataLoad(chain);
  return Belt_Option.map(Belt_Option.flatMap(optData, (function (data) {
                    return data.global;
                  })), (function ($$global) {
                return $$global.defaultAuctionEndPrice;
              }));
}

function useAuctioLength(chain, _tokenId) {
  var optData = useInitialDataLoad(chain);
  return Belt_Option.map(Belt_Option.flatMap(optData, (function (data) {
                    return data.global;
                  })), (function ($$global) {
                return $$global.defaultAuctionLength;
              }));
}

function useLaunchTimeBN(chain, tokenId) {
  var match = useWildcardQuery(chain, undefined, tokenId);
  if (match === undefined) {
    return ;
  }
  var match$1 = match.wildcard;
  if (match$1 !== undefined) {
    return Caml_option.some(match$1.launchTime);
  }
  
}

function useMaticState(forceRefetch, address, network) {
  var query = Curry.app(use$3, [
        undefined,
        undefined,
        undefined,
        undefined,
        forceRefetch ? /* CacheAndNetwork */0 : /* CacheFirst */1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          address: address,
          network: network
        }
      ]);
  var match = query.data;
  if (query.loading || query.error !== undefined || match === undefined) {
    return ;
  } else {
    return match.maticState;
  }
}

function useArtistData(artistIdentifier) {
  var artistQuery = Curry.app(use$5, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          artistIdentifier: artistIdentifier
        }
      ]);
  var match = artistQuery.data;
  if (artistQuery.loading || artistQuery.error !== undefined || match === undefined) {
    return ;
  } else {
    return match.artist_by_pk;
  }
}

function useArtistEthAddress(artistIdentifier) {
  var artistData = useArtistData(artistIdentifier);
  return Belt_Option.flatMap(artistData, (function (data) {
                return data.eth_address;
              }));
}

function useArtistName(artistIdentifier) {
  var artistData = useArtistData(artistIdentifier);
  return Belt_Option.map(artistData, (function (data) {
                return data.name;
              }));
}

function useArtistWebsite(artistIdentifier) {
  var artistData = useArtistData(artistIdentifier);
  return Belt_Option.flatMap(artistData, (function (data) {
                return data.website;
              }));
}

function useArtistLaunchedWildcards(artistIdentifier) {
  var artistData = useArtistData(artistIdentifier);
  return Belt_Option.map(artistData, (function (data) {
                return data.launchedWildcards;
              }));
}

function useArtistUnlaunchedWildcards(artistIdentifier) {
  var artistData = useArtistData(artistIdentifier);
  return Belt_Option.map(artistData, (function (data) {
                return data.unlaunchedWildcards;
              }));
}

function useArtistOrgs(artistIdentifier) {
  var artistData = useArtistData(artistIdentifier);
  return Belt_Option.map(artistData, (function (data) {
                var dict = {};
                Belt_Array.map(data.launchedWildcards, (function (wildcard) {
                        var org = wildcard.organization;
                        if (org === undefined) {
                          return ;
                        }
                        var orgId = org.id;
                        var orgObj = Js_dict.get(dict, orgId);
                        if (orgObj !== undefined) {
                          var newOrgObj_id = orgObj.id;
                          var newOrgObj_name = orgObj.name;
                          var newOrgObj_logo = orgObj.logo;
                          var newOrgObj_wildcards = Belt_Array.concat(orgObj.wildcards, [wildcard.key]);
                          var newOrgObj = {
                            id: newOrgObj_id,
                            name: newOrgObj_name,
                            logo: newOrgObj_logo,
                            wildcards: newOrgObj_wildcards
                          };
                          dict[orgId] = newOrgObj;
                          return ;
                        }
                        dict[orgId] = {
                          id: orgId,
                          name: org.name,
                          logo: org.logo,
                          wildcards: [wildcard.key]
                        };
                      }));
                return Js_dict.values(dict);
              }));
}

var QueryFetchPolicy;

export {
  QueryFetchPolicy ,
  BlockchainWildcardData ,
  WildcardMainData ,
  InitialLoad ,
  createContext ,
  chainToContext ,
  useInitialDataLoad ,
  useAnimalList ,
  WildcardChainDataQuery ,
  WildcardDataQuery ,
  MaticStateQuery ,
  HomeAnimalsQuery ,
  ArtistQuery ,
  LoadPatron ,
  LoadTokenDataArray ,
  LoadOrganisationData ,
  LoadTopContributors ,
  SubTotalRaisedOrDueQuery ,
  getQueryPrefix ,
  useWildcardQuery ,
  useLoadTokenDataArrayQuery ,
  useWildcardDataQuery ,
  useHomeAnimalsQuery ,
  useLoadOrganisationQuery ,
  useLoadOrganisationLogo ,
  useLoadOrganisationLogoBadge ,
  useHomePageAnimalArray ,
  useDetailsPageNextPrevious ,
  animalDescription_decode ,
  useWildcardDescription ,
  useWildcardName ,
  useWildcardCommonName ,
  useWildcardAvatar ,
  useWildcardArtist ,
  useRealImages ,
  useWildcardOrgId ,
  useWildcardOrgName ,
  useLoadTopContributors ,
  useLoadTopContributorsData ,
  usePatron ,
  useIsAnimalOwned ,
  useTimeAcquired ,
  useQueryPatronQuery ,
  useQueryPatron ,
  useForeclosureTimeBn ,
  useForeclosureTime ,
  useTimeAcquiredWithDefault ,
  useDaysHeld ,
  useTotalCollectedOrDue ,
  getCurrentTimestamp ,
  useCurrentTime ,
  useCurrentTimestampBn ,
  useAmountRaised ,
  useTotalCollectedToken ,
  usePatronageNumerator ,
  usePledgeRate ,
  usePledgeRateDetailed ,
  usePatronLoyaltyTokenDetails ,
  useAmountRaisedToken ,
  calculateTotalRaised ,
  useTotalRaisedAnimalGroup ,
  useTimeSinceTokenWasLastSettled ,
  useUnredeemedLoyaltyTokenDueForUser ,
  useTotalLoyaltyToken ,
  useRemainingDepositEth ,
  usePrice ,
  useIsForeclosed ,
  useAuctionStartPrice ,
  useAuctionEndPrice ,
  useAuctioLength ,
  useLaunchTimeBN ,
  useMaticState ,
  useArtistData ,
  useArtistEthAddress ,
  useArtistName ,
  useArtistWebsite ,
  useArtistLaunchedWildcards ,
  useArtistUnlaunchedWildcards ,
  useArtistOrgs ,
}
/* query Not a pure module */
