// Generated by ReScript, PLEASE EDIT WITH CARE

import BlockieDefaultJs from "./blockie-default.js";

var makeBlockie = BlockieDefaultJs;

export {
  makeBlockie ,
}
/* makeBlockie Not a pure module */
