// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../../Styles.bs.js";
import * as RimbleUi from "rimble-ui";
import * as RootProvider from "../RootProvider.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var infoModalStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.rem(3)),
      tl: {
        hd: Css.borderRadius(Css.px(5)),
        tl: {
          hd: Css.width(Css.px(640)),
          tl: {
            hd: Css.maxWidth({
                  NAME: "vw",
                  VAL: 100
                }),
            tl: /* [] */0
          }
        }
      }
    });

function Web3Connect(props) {
  var clickAction = props.clickAction;
  var clickAction$1 = clickAction !== undefined ? clickAction : (function (param) {
        
      });
  var connectWeb3 = RootProvider.useConnectWeb3(undefined);
  var deactivateWeb3 = RootProvider.useDeactivateWeb3(undefined);
  var networkIdOpt = RootProvider.useNetworkId(undefined);
  var connectedNetworkName = function (networkId) {
    if (networkId >= 43) {
      if (networkId !== 137) {
        if (networkId !== 80001) {
          return "Unknown\\xa0";
        } else {
          return "MATIC Test\\xa0";
        }
      } else {
        return "MATIC\\xa0";
      }
    }
    if (networkId >= 6) {
      if (networkId >= 42) {
        return "KOVAN\\xa0";
      } else {
        return "Unknown\\xa0";
      }
    }
    if (networkId <= 0) {
      return "Unknown\\xa0";
    }
    switch (networkId) {
      case 1 :
          return "MAINNET\\xa0";
      case 2 :
          return "Unknown\\xa0";
      case 3 :
          return "ROPSTEN\\xa0";
      case 4 :
          return "RINKEBY\\xa0";
      case 5 :
          return "GOERLI\\xa0";
      
    }
  };
  var web3Button = networkIdOpt !== undefined ? React.createElement(RimbleUi.Button, {
          mainColor: "#72C7D7",
          children: null,
          onClick: (function (_e) {
              Curry._1(clickAction$1, undefined);
              Curry._1(deactivateWeb3, undefined);
            })
        }, connectedNetworkName(networkIdOpt), React.createElement(RimbleUi.Icon, {
              name: "ExitToApp",
              size: "16px"
            })) : React.createElement(RimbleUi.Button, {
          mainColor: "#72C7D7",
          children: "Log In",
          onClick: (function (_e) {
              Curry._1(clickAction$1, undefined);
              RescriptReactRouter.push("#");
              Curry._1(connectWeb3, /* NoAction */0);
            })
        });
  return React.createElement("div", {
              className: Styles.loginButton
            }, web3Button);
}

var make = Web3Connect;

export {
  infoModalStyle ,
  make ,
}
/* infoModalStyle Not a pure module */
