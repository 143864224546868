// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../../Styles.bs.js";
import * as RimbleUi from "rimble-ui";
import YoutubeVideoJs from "./YoutubeVideo.js";

var gorillaOnVine = "/img/wildcardsimages/hanging-gorilla-from-vine.png";

var make = YoutubeVideoJs;

var YoutubeVid = {
  make: make
};

var infoModalStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.rem(3)),
      tl: {
        hd: Css.borderRadius(Css.px(5)),
        tl: {
          hd: Css.width(Css.px(640)),
          tl: {
            hd: Css.maxWidth({
                  NAME: "vw",
                  VAL: 100
                }),
            tl: /* [] */0
          }
        }
      }
    });

function HowItWorks(props) {
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  return React.createElement(RimbleUi.Box, {
              children: React.createElement(RimbleUi.Flex, {
                    children: null,
                    flexWrap: "wrap"
                  }, React.createElement(RimbleUi.Box, {
                        children: React.createElement(RimbleUi.Card, {
                              className: Styles.infoCardStyles,
                              children: null
                            }, React.createElement(make, {
                                  videoCode: "n7GBm6ruVaQ"
                                }), React.createElement("br", undefined), React.createElement(RimbleUi.Flex, {
                                  children: React.createElement(RimbleUi.Button, {
                                        className: Styles.centerItemsMargin,
                                        children: "Read More",
                                        onClick: (function (param) {
                                            Curry._1(setModalOpen, (function (param) {
                                                    return true;
                                                  }));
                                          })
                                      }),
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                  justifyContent: "space-around",
                                  px: 50,
                                  pt: 50
                                }), React.createElement(RimbleUi.Modal, {
                                  isOpen: match[0],
                                  children: React.createElement(RimbleUi.Card, {
                                        className: infoModalStyle,
                                        children: null
                                      }, React.createElement(RimbleUi.Button.Text, {
                                            onClick: (function (param) {
                                                Curry._1(setModalOpen, (function (param) {
                                                        return false;
                                                      }));
                                              }),
                                            icononly: true,
                                            icon: "Close",
                                            color: "moon-gray",
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            m: 3
                                          }), React.createElement(RimbleUi.Heading, {
                                            children: "How it works",
                                            className: Styles.centerText
                                          }), React.createElement("br", undefined), React.createElement("hr", undefined), React.createElement(RimbleUi.Text, {
                                            children: null
                                          }, "You can become the guardian of a wildcard by simply clicking buy on any of the listed wildcards. Wildcards are ", React.createElement("strong", undefined, "always for sale"), " since anyone can purchase them at anytime for their current sale price. When someone purchases a wildcard, they are required to immediately set a sale price for that wildcard. This ensures wildcards are ", React.createElement("strong", undefined, "always for sale")), React.createElement("br", undefined), React.createElement(RimbleUi.Text, {
                                            children: null
                                          }, "As the gaurdian of a wildcard, you pay a ", React.createElement("strong", undefined, "Harberger tax"), " (percentage based fee) on your newly set sale price. This fee goes towards supporting wildlife conservation. "), React.createElement("br", undefined), React.createElement(RimbleUi.Text, {
                                            children: null
                                          }, React.createElement("strong", undefined, "Here's where it gets interesting."), " When someone purchases your wildcard from you, you will receive the sale price you set for that wildcard. Depending on what sale price you set and the harberger tax you have already paid, you may make a profit. The only constant is that the conservation organisation will continually receive funding based on the current selling price of each wildcard."), React.createElement("br", undefined), React.createElement(RimbleUi.Text, {
                                            children: React.createElement("span", {
                                                  className: Styles.redDisclaimer
                                                }, "The first wildcard we released, Vitalik, has a 2.5% Harberger tax rate per month. Other wildcards have different harberger tax rates ranging from 2.5% to 100% per month.")
                                          }))
                                })),
                        width: [
                          1,
                          1,
                          0.59
                        ],
                        className: Styles.infoCardContainer
                      }), React.createElement(RimbleUi.Box, {
                        children: React.createElement("img", {
                              src: gorillaOnVine,
                              width: "100%"
                            }),
                        width: [0.41],
                        className: Styles.animalImage
                      })),
              className: Styles.infoBackground
            });
}

var make$1 = HowItWorks;

export {
  gorillaOnVine ,
  YoutubeVid ,
  infoModalStyle ,
  make$1 as make,
}
/* make Not a pure module */
