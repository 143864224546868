// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function DiscordChat(props) {
  var match = React.useState(function () {
        return false;
      });
  var setShowChat = match[1];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.bottom(Css.px(10)),
                      tl: {
                        hd: Css.right(Css.px(10)),
                        tl: {
                          hd: Css.zIndex(1000),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, match[0] ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: /* [] */0
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(50)),
                              tl: {
                                hd: Css.width(Css.px(50)),
                                tl: {
                                  hd: Css.marginLeft("auto"),
                                  tl: {
                                    hd: Css.backgroundColor(Css.hex("CCCCCCCC")),
                                    tl: {
                                      hd: Css.borderRadius({
                                            NAME: "percent",
                                            VAL: 50
                                          }),
                                      tl: {
                                        hd: Css.marginBottom(Css.rem(0.2)),
                                        tl: {
                                          hd: Css.selector(":hover", {
                                                hd: Css.important(Css.backgroundColor(Css.hex("6CAD3DCC"))),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        onClick: (function (param) {
                            Curry._1(setShowChat, (function (param) {
                                    return false;
                                  }));
                          })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding(Css.rem(1)),
                                  tl: {
                                    hd: Css.fontSize(Css.px(16)),
                                    tl: {
                                      hd: Css.transform(Css.translateX(Css.px(3))),
                                      tl: {
                                        hd: Css.color(Css.white),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                })
                          }, "✖")), React.createElement("iframe", {
                        className: Curry._1(Css.style, {
                              hd: Css.borderWidth(Css.px(0)),
                              tl: /* [] */0
                            }),
                        height: "500",
                        sandbox: "allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts",
                        src: "https://discordapp.com/widget?id=723502058426073108&theme=dark",
                        width: "350"
                      })) : React.createElement("img", {
                    className: Curry._1(Css.style, {
                          hd: Css.height(Css.px(50)),
                          tl: {
                            hd: Css.width(Css.px(50)),
                            tl: {
                              hd: Css.marginLeft("auto"),
                              tl: /* [] */0
                            }
                          }
                        }),
                    src: "/img/icons/discord.svg",
                    onClick: (function (param) {
                        Curry._1(setShowChat, (function (param) {
                                return true;
                              }));
                      })
                  }));
}

var make = DiscordChat;

export {
  make ,
}
/* Css Not a pure module */
