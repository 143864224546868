// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function announcementStyle(displayVal, announcementBannerColor) {
  return Curry._1(Css.style, {
              hd: Css.display(displayVal),
              tl: {
                hd: Css.position(Css.relative),
                tl: {
                  hd: Css.padding2(Css.rem(0.4), Css.rem(1)),
                  tl: {
                    hd: Css.color(Css.white),
                    tl: {
                      hd: Css.backgroundColor(Css.hex(announcementBannerColor)),
                      tl: {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.zIndex(2),
                          tl: {
                            hd: Css.fontSize(Css.px(14)),
                            tl: {
                              hd: Css.letterSpacing(Css.px(2)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Announcement(props) {
  var closeButton = Curry._1(Css.style, {
        hd: Css.position(Css.absolute),
        tl: {
          hd: Css.right(Css.px(10)),
          tl: /* [] */0
        }
      });
  var match = React.useState(function () {
        return "block";
      });
  var setShowAnnouncement = match[1];
  return React.createElement("div", {
              className: announcementStyle(match[0], props.announcementBannerColor)
            }, props.children, React.createElement("span", {
                  className: closeButton,
                  onClick: (function (param) {
                      Curry._1(setShowAnnouncement, (function (param) {
                              return "none";
                            }));
                    })
                }, "×"));
}

var make = Announcement;

export {
  announcementStyle ,
  make ,
}
/* Css Not a pure module */
