// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import BnJs from "bn.js";
import * as React from "react";
import * as Helper from "../../harberger-lib/Helper.bs.js";
import * as QlHooks from "../../harberger-lib/QlHooks.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as RimbleUi from "rimble-ui";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RootProvider from "../../harberger-lib/RootProvider.bs.js";
import * as UserProvider from "../../harberger-lib/js/user-provider/UserProvider.bs.js";
import * as GqlConverters from "../../gql/GqlConverters.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query   {
    patrons(first: 20, orderBy: totalTimeHeld, orderDirection: desc, where: {id_not: "NO_OWNER"})  {
      __typename
      id
      totalTimeHeld
      tokens  {
        __typename
        id
      }
      lastUpdated
    }
  }
`);

function parse(value) {
  var value$1 = value.patrons;
  return {
          patrons: Js_array.map((function (value) {
                  var value$1 = value.tokens;
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          totalTimeHeld: GqlConverters.$$BigInt.parse(value.totalTimeHeld),
                          tokens: Js_array.map((function (value) {
                                  return {
                                          __typename: value.__typename,
                                          id: value.id
                                        };
                                }), value$1),
                          lastUpdated: GqlConverters.$$BigInt.parse(value.lastUpdated)
                        };
                }), value$1)
        };
}

function serialize(value) {
  var value$1 = value.patrons;
  var patrons = Js_array.map((function (value) {
          var value$1 = value.lastUpdated;
          var value$2 = GqlConverters.$$BigInt.serialize(value$1);
          var value$3 = value.tokens;
          var tokens = Js_array.map((function (value) {
                  var value$1 = value.id;
                  var value$2 = value.__typename;
                  return {
                          __typename: value$2,
                          id: value$1
                        };
                }), value$3);
          var value$4 = value.totalTimeHeld;
          var value$5 = GqlConverters.$$BigInt.serialize(value$4);
          var value$6 = value.id;
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  id: value$6,
                  totalTimeHeld: value$5,
                  tokens: tokens,
                  lastUpdated: value$2
                };
        }), value$1);
  return {
          patrons: patrons
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var LoadMostDaysHeld_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var LoadMostDaysHeld_refetchQueryDescription = include.refetchQueryDescription;

var LoadMostDaysHeld_useLazy = include.useLazy;

var LoadMostDaysHeld_useLazyWithVariables = include.useLazyWithVariables;

var LoadMostDaysHeld = {
  LoadMostDaysHeld_inner: LoadMostDaysHeld_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: LoadMostDaysHeld_refetchQueryDescription,
  use: use,
  useLazy: LoadMostDaysHeld_useLazy,
  useLazyWithVariables: LoadMostDaysHeld_useLazyWithVariables
};

function useLoadMostDaysHeldData(param) {
  var currentTimestamp = QlHooks.useCurrentTime(undefined);
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match$1 = match.data;
  if (match.loading || match.error !== undefined || match$1 === undefined) {
    return ;
  } else {
    return Belt_Array.map(match$1.patrons, (function (patron) {
                    var numberOfTokens = String(patron.tokens.length);
                    var timeElapsed = new BnJs(currentTimestamp).sub(patron.lastUpdated);
                    var totalTimeHeldWei = patron.totalTimeHeld.add(timeElapsed.mul(new BnJs(numberOfTokens)));
                    return [
                            patron.id,
                            totalTimeHeldWei
                          ];
                  })).sort(function (param, param$1) {
                return param$1[1].cmp(param[1]);
              });
  }
}

var goldTrophyImg = "/img/icons/gold-trophy.png";

var silverTrophyImg = "/img/icons/silver-trophy.png";

var bronzeTrophyImg = "/img/icons/bronze-trophy.png";

var leaderboardTable = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100
          }),
      tl: {
        hd: Css.tableLayout("fixed"),
        tl: {
          hd: Css.overflowWrap("breakWord"),
          tl: /* [] */0
        }
      }
    });

var leaderboardHeader = Curry._1(Css.style, {
      hd: Css.backgroundColor({
            NAME: "hex",
            VAL: "73c7d7ff"
          }),
      tl: /* [] */0
    });

var streakTextLeaderboard = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.zIndex(100),
        tl: {
          hd: Css.bottom({
                NAME: "percent",
                VAL: -10
              }),
          tl: {
            hd: Css.right({
                  NAME: "percent",
                  VAL: 50
                }),
            tl: {
              hd: Css.transform(Css.translateX({
                        NAME: "px",
                        VAL: -5
                      })),
              tl: /* [] */0
            }
          }
        }
      }
    });

var flameImgLeaderboard = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100
          }),
      tl: {
        hd: Css.maxWidth(Css.px(50)),
        tl: /* [] */0
      }
    });

var rankText = Curry._1(Css.style, {
      hd: Css.position(Css.absolute),
      tl: {
        hd: Css.zIndex(100),
        tl: {
          hd: Css.bottom({
                NAME: "percent",
                VAL: -10
              }),
          tl: {
            hd: Css.right({
                  NAME: "percent",
                  VAL: 50
                }),
            tl: {
              hd: Css.transform(Css.translate({
                        NAME: "px",
                        VAL: -4
                      }, {
                        NAME: "px",
                        VAL: -15
                      })),
              tl: /* [] */0
            }
          }
        }
      }
    });

var trophyImg = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "percent",
            VAL: 100
          }),
      tl: {
        hd: Css.width(Css.px(50)),
        tl: {
          hd: Css.height(Css.px(50)),
          tl: /* [] */0
        }
      }
    });

var centerFlame = Curry._1(Css.style, {
      hd: Css.display(Css.block),
      tl: {
        hd: Css.margin(Css.auto),
        tl: {
          hd: Css.width({
                NAME: "px",
                VAL: 70
              }),
          tl: {
            hd: Css.position(Css.relative),
            tl: /* [] */0
          }
        }
      }
    });

var rankMetric = Curry._1(Css.style, {
      hd: Css.fontSize({
            NAME: "px",
            VAL: 16
          }),
      tl: /* [] */0
    });

function rankingColor(index) {
  return Curry._1(Css.style, {
              hd: Css.backgroundColor({
                    NAME: "hex",
                    VAL: index % 2 === 1 ? "b5b5bd22" : "ffffffff"
                  }),
              tl: /* [] */0
            });
}

function TotalDaysHeld$ContributorsRow(props) {
  var index = props.index;
  var contributor = props.contributor;
  Curry._2(UserProvider.useUserInfoContext(undefined).update, contributor, false);
  var optThreeBoxData = UserProvider.use3BoxUserData(contributor);
  var optUserName = Belt_Option.flatMap(Belt_Option.flatMap(optThreeBoxData, (function (threeBoxData) {
              return threeBoxData.profile;
            })), (function (threeBoxData) {
          return threeBoxData.name;
        }));
  var clearAndPush = RootProvider.useClearNonUrlStateAndPushRoute(undefined);
  return React.createElement("tr", {
              key: contributor,
              className: rankingColor(index)
            }, React.createElement("td", undefined, React.createElement("span", {
                      className: centerFlame
                    }, index === 0 ? React.createElement("img", {
                            className: trophyImg,
                            src: goldTrophyImg
                          }) : (
                        index === 1 ? React.createElement("img", {
                                className: trophyImg,
                                src: silverTrophyImg
                              }) : (
                            index === 2 ? React.createElement("img", {
                                    className: trophyImg,
                                    src: bronzeTrophyImg
                                  }) : React.createElement("div", {
                                    className: trophyImg
                                  })
                          )
                      ), React.createElement("p", {
                          className: rankText
                        }, React.createElement("strong", undefined, "#", String(index + 1 | 0))))), React.createElement("td", undefined, React.createElement("a", {
                      onClick: (function (e) {
                          e.preventDefault();
                          Curry._1(clearAndPush, "/#user/$contributor");
                        })
                    }, optUserName !== undefined ? React.createElement("span", undefined, optUserName) : React.createElement("span", undefined, Helper.elipsify(contributor, 20)))), React.createElement("td", {
                  className: rankMetric
                }, props.amount + " Days"));
}

var ContributorsRow = {
  make: TotalDaysHeld$ContributorsRow
};

function TotalDaysHeld$MostDaysHeld(props) {
  return Belt_Array.mapWithIndex(props.mostDaysHeld, (function (index, param) {
                return React.createElement(TotalDaysHeld$ContributorsRow, {
                            contributor: param[0],
                            amount: param[1].div(new BnJs("86400")).toString(),
                            index: index
                          });
              }));
}

var MostDaysHeld = {
  make: TotalDaysHeld$MostDaysHeld
};

function TotalDaysHeld(props) {
  var mostDaysHeldOpt = useLoadMostDaysHeldData(undefined);
  var tmp;
  if (mostDaysHeldOpt !== undefined) {
    var mostDaysHeld = Belt_Array.slice(mostDaysHeldOpt, 0, props.numberOfLeaders);
    tmp = React.createElement(TotalDaysHeld$MostDaysHeld, {
          mostDaysHeld: mostDaysHeld
        });
  } else {
    tmp = null;
  }
  return React.createElement("div", undefined, React.createElement(RimbleUi.Heading, {
                  children: "Wildcards Accumulative Days Held Leaderboard"
                }), React.createElement("br", undefined), React.createElement(RimbleUi.Table, {
                  children: null,
                  className: leaderboardTable
                }, React.createElement("thead", {
                      className: leaderboardHeader
                    }, React.createElement("tr", undefined, React.createElement("th", undefined, "Rank"), React.createElement("th", undefined, "Guardian"), React.createElement("th", undefined, "Accumulative Days Held"))), React.createElement("tbody", undefined, tmp)));
}

var flameImg = "/img/streak-flame.png";

var make = TotalDaysHeld;

export {
  LoadMostDaysHeld ,
  useLoadMostDaysHeldData ,
  flameImg ,
  goldTrophyImg ,
  silverTrophyImg ,
  bronzeTrophyImg ,
  leaderboardTable ,
  leaderboardHeader ,
  streakTextLeaderboard ,
  flameImgLeaderboard ,
  rankText ,
  trophyImg ,
  centerFlame ,
  rankMetric ,
  rankingColor ,
  ContributorsRow ,
  MostDaysHeld ,
  make ,
}
/* query Not a pure module */
